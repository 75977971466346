import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { breaksResetAtom } from "../../recoil/breakAtoms";
import { PeriodRecord } from "../../types/types";

type Props = {
  periodToEdit: PeriodRecord;
  setPeriodToEdit: (pV: PeriodRecord | null) => void;
};

export default function EditPeriodDialog({ periodToEdit, setPeriodToEdit }: Props) {
  const [label, setLabel] = useState(periodToEdit.label);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setBreaksReset = useSetRecoilState(breaksResetAtom);
  const handleClose = () => {
    setPeriodToEdit(null);
  };

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const handleSave = async () => {
    await updateDoc({ col: "periods", data: { label }, id: periodToEdit.id });
    setPeriodToEdit(null);
    setBreaksReset((pV: boolean) => !pV);
  };

  return (
    <>
      <Dialog open={Boolean(periodToEdit)} onClose={handleClose}>
        <DialogTitle>Edit Label</DialogTitle>
        <DialogContent>
          <TextField fullWidth value={label} onChange={handleLabelChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
