import React, { useCallback, useEffect, useRef, useState } from "react";
import { Homeroom, TimeAssignmentRecord } from "../../types/types";
import { DateTime, Interval } from "luxon";
import { useDraggable } from "@dnd-kit/core";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, IconButton, Typography } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { staffObjAtom } from "../../recoil/staffAtoms";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import DeleteIcon from "@mui/icons-material/Delete";
import { timeAssignmentsResetAtom } from "../../recoil/timeAssignmentAtoms";
import useDeleteDoc from "../../hooks/useDeleteDoc";
import useUpdateRTDoc from "../../hooks/useUpdateRTDoc";
import useDeleteRTDoc from "../../hooks/useDeleteRTDoc";

type Props = {
  rowHeight: number;
  columnWidth: number;
  timeAssignment: TimeAssignmentRecord;
  startTime: string;
  timeInterval: number;
  homerooms: Homeroom[];
  initialLeft: number;
};

const TimeAssignment = ({
  rowHeight,
  columnWidth,
  timeAssignment,
  startTime,
  timeInterval,
  homerooms,
  initialLeft,
}: Props) => {
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: deleteDoc } = useDeleteDoc();
  const setTimeAssignmentsReset = useSetRecoilState(timeAssignmentsResetAtom);
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: timeAssignment.id,
    data: { startTime: timeAssignment.startTime, endTime: timeAssignment.endTime },
  });
  const staffObj = useRecoilValue(staffObjAtom);
  const { sendRequest: updateRTDoc } = useUpdateRTDoc();
  const { sendRequest: deleteRTDoc } = useDeleteRTDoc();

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    document.addEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    if (!timeAssignment) return;
    const dtEndTime = DateTime.fromFormat(timeAssignment.endTime, "t");
    const dtStartTime = DateTime.fromFormat(timeAssignment.startTime, "t");
    const diff = Interval.fromDateTimes(dtStartTime, dtEndTime);
    let tempHeight = rowHeight;
    if (diff.isValid) {
      tempHeight = Math.round((diff.length("minutes") / timeInterval) * rowHeight);
    }
    setHeight(tempHeight);
  }, [timeAssignment, timeInterval, rowHeight]);

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (!containerRef.current || containerRef.current.offsetHeight === 0) return;
      setHeight(containerRef.current.offsetHeight);
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleMouseUp = async () => {
    if (!containerRef.current) return;
    document.removeEventListener("mouseup", handleMouseUp);
    const rawHeight = containerRef.current.offsetHeight;
    const newHeight = Math.round(rawHeight / rowHeight) * rowHeight;
    const newMinutes = (newHeight / rowHeight) * timeInterval;
    const dtEndTime = DateTime.fromFormat(timeAssignment.startTime, "t").plus({
      minutes: newMinutes,
    });
    if (newHeight !== height) {
      setHeight(newHeight);
      // await updateDoc({
      //   col: "timeAssignments",
      //   data: { endTime: dtEndTime.toFormat("t") },
      //   id: timeAssignment.id,
      // });

      if (!timeAssignment) return;
      await updateRTDoc({
        data: { ...timeAssignment, endTime: dtEndTime.toFormat("t") },
        path: `timeAssignments/${timeAssignment.staffId}/${timeAssignment.id}`,
      });
      //setTimeAssignmentsReset((pV) => !pV);
    }
  };

  const calcTop = (periodStart: string) => {
    const dtPeriodStart = DateTime.fromFormat(periodStart, "t");
    const dtStartTime = DateTime.fromFormat(startTime, "t");
    const diff = Interval.fromDateTimes(dtStartTime, dtPeriodStart);
    return Math.round((diff.length("minutes") / timeInterval) * rowHeight);
  };

  const calcLeft = (homeroomId: string) => {
    const index = homerooms.findIndex((homeroom) => homeroom.id === homeroomId);
    return index;
  };

  const handleDeleteClick = () => {
    //setDeleteId(timeAssignment.id);
    deleteRTDoc({ path: `timeAssignments/${timeAssignment.staffId}/${timeAssignment.id}` });
    //deleteDoc({ col: "timeAssignments", id: timeAssignment.id });
    //setTimeAssignmentsReset((pV) => !pV);
  };

  const formatName = (id: string) => {
    if (!staffObj || !staffObj[id]) return "N/A";
    const staff = staffObj[id];
    return `${staff.firstName.slice(0, 1)}. ${staff.lastName.slice(0, 2)}`;
  };

  const handleNameClick = () => {
    console.log("clicked");
  };

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: calcTop(timeAssignment.startTime),
          left: timeAssignment.slot * 100 + calcLeft(timeAssignment.homeroomId) * columnWidth + 100,
          ...style,
        }}
        ref={setNodeRef}
      >
        <div
          ref={containerRef}
          style={{
            height: `${height}px`,
            overflow: "hidden",
            resize: "vertical",
            backgroundColor: "rgba(246, 230, 73, .8)",
            zIndex: 99,
            position: "relative",
            width: 100,
            boxShadow: "0 0 1px #333",
            borderRadius: 5,
          }}
          className="resizeable"
          onMouseDown={handleMouseDown}
        >
          <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
            <DragIndicatorIcon {...listeners} {...attributes} />
            <IconButton sx={{ padding: 0, ml: "2px", mr: "2px" }} onClick={handleDeleteClick}>
              <DeleteIcon fontSize="small" />
            </IconButton>
            {staffObj && staffObj[timeAssignment.staffId] && (
              <Typography onClick={handleNameClick} sx={{ fontSize: 12, cursor: "pointer" }}>
                {formatName(timeAssignment.staffId)}
              </Typography>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default TimeAssignment;
