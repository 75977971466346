import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedStaffMemberIdAtom, staffAtom } from "../../recoil/staffAtoms";

function Header() {
  const staff = useRecoilValue(staffAtom);
  const [selectedStaffMemberId, setSelectedStaffMemberId] =
    useRecoilState(selectedStaffMemberIdAtom);
  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedStaffMemberId(event.target.value);
  };

  return (
    <>
      {selectedStaffMemberId && (
        <Box sx={{ pt: 1, pl: 4, pr: 4 }}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={9}>
              <Typography variant="h2">Schedule By Class</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="selected-staff">Select Staff Member</InputLabel>
                <Select
                  value={selectedStaffMemberId}
                  labelId="selected-staff"
                  required
                  label="Select Staff Member"
                  onChange={handleSelectChange}
                >
                  {staff &&
                    staff.map((staffMember) => (
                      <MenuItem key={staffMember.id} value={staffMember.id}>
                        {staffMember.firstName} {staffMember.lastName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default Header;
