import { Menu, MenuItem, Box, CircularProgress } from "@mui/material";
import useTemplates from "../../hooks/useTemplates";
import { useSetRecoilState } from "recoil";
import { timeAssignmentTemplatesResetAtom } from "../../recoil/timeAssignmentAtoms";
import { breakTemplatesResetAtom } from "../../recoil/breakAtoms";

type Props = {
  anchorEl: null | HTMLElement;
  setAnchorEl: (pV: null | HTMLElement) => void;
  selectedStaffMemberId: string | null;
};

export default function StaffMenu({ anchorEl, setAnchorEl, selectedStaffMemberId }: Props) {
  const open = Boolean(anchorEl);
  const setTimeAssignmentTemplatesReset = useSetRecoilState(timeAssignmentTemplatesResetAtom);
  const setBreakTemplatesReset = useSetRecoilState(breakTemplatesResetAtom);
  const { loading, updateToDefault, setScheduleAsDefault, clearSchedule } = useTemplates();

  const handleClear = () => {
    if (!selectedStaffMemberId) return;
    clearSchedule(selectedStaffMemberId);
    handleClose();
  };

  const handleUpdateDefault = async () => {
    if (!selectedStaffMemberId) return;
    await setScheduleAsDefault(selectedStaffMemberId);
    setTimeAssignmentTemplatesReset((pV) => !pV);
    setBreakTemplatesReset((pV) => !pV);
    handleClose();
  };

  const handleSetToDefault = async () => {
    if (!selectedStaffMemberId) return;
    await updateToDefault(selectedStaffMemberId);
    setTimeAssignmentTemplatesReset((pV) => !pV);
    setBreakTemplatesReset((pV) => !pV);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {loading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleClear}>Clear</MenuItem>
          <MenuItem onClick={handleSetToDefault}>Set to Default</MenuItem>
          <MenuItem onClick={handleUpdateDefault}>Set As Default</MenuItem>
        </Menu>
      )}
    </div>
  );
}
