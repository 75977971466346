import { useState, useCallback, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { staffAtom, staffFromAPIAtom } from "../../recoil/staffAtoms";
import { Fab, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StaffCard from "./StaffCard";
import CreateDialog from "../Staff/CreateDialog";
import useAddDoc from "../../hooks/useAddDoc";

const StaffContainer = () => {
  const [createOpen, setCreateOpen] = useState(false);
  const staff = useRecoilValue(staffAtom);
  const staffFromAPI = useRecoilValue(staffFromAPIAtom);
  const { sendRequest: addDoc } = useAddDoc();

  useEffect(() => {
    if (!staffFromAPI || !staff || staff.length === 0) return;
    const staffEmails = staff.map((staffMember) => staffMember.email);
    staffFromAPI.forEach((staffMember) => {
      if(staffMember.terminated) return;
      if (!staffEmails.includes(staffMember.email)) {
        console.log(staffMember.email)
        // addDoc({
        //   col: "staff",
        //   data: {
        //     firstName: staffMember.firstName,
        //     lastName: staffMember.lastName,
        //     email: staffMember.email,
        //     homerooms: staffMember.homerooms,
        //     permissions: [],
        //   },
        // });
      }
    });
  }, [staff, staffFromAPI, addDoc]);

  const handleNewStaff = useCallback(() => {
    setCreateOpen(true);
  }, []);

  return (
    <>
      <Typography variant="h1" sx={{ mt: 3 }}>
        Staff
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {staff && staff.map((s) => <StaffCard key={s.id} staff={s} />)}
      </Grid>
      <Fab
        sx={{ position: "fixed", bottom: "5vh", right: "5vw" }}
        color="primary"
        aria-label="add"
        onClick={() => handleNewStaff()}
      >
        <AddIcon />
      </Fab>
      <CreateDialog open={createOpen} onOpen={setCreateOpen} />
    </>
  );
};

export default StaffContainer;
