import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { Staff } from "../../types/types";
import { useEffect, useState } from "react";
import { staffAtom, staffFromAPIAtom } from "../../recoil/staffAtoms";

type Props = {
  setFilteredStaff: (pV: Staff[]) => void;
  setClickReady: (pV: boolean) => void;
  filteredStaff: Staff[];
};

export default function DepartmentFilter({
  setFilteredStaff,
  filteredStaff,
  setClickReady,
}: Props) {
  const staff = useRecoilValue(staffAtom);
  const staffFromAPI = useRecoilValue(staffFromAPIAtom);
  const [filteredStaffIds, setFilteredStaffIds] = useState<string[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);

  useEffect(() => {
    const tempDepartments: string[] = [];
    staffFromAPI.forEach((staffMember) => {
      if (!tempDepartments.includes(staffMember.department)) {
        tempDepartments.push(staffMember.department);
      }
    });
    setDepartments(tempDepartments);
    console.log(tempDepartments)
    const startingFilteredDepartments = ["Teaching Assistants", "Substitutes", "Teachers", "Literacy Team", "Arts Team"];
    setSelectedDepartments(startingFilteredDepartments);
    const tempFilteredStaffFromAPI = staffFromAPI.filter((staffMember) =>
      startingFilteredDepartments.includes(staffMember.department)
    );
    const tempFilteredStaffEmails = tempFilteredStaffFromAPI.map(
      (staffMember) => staffMember.email
    );
    const tempFilteredStaff = staff.filter((staffMember) =>
      tempFilteredStaffEmails.includes(staffMember.email)
    );
    setFilteredStaff(tempFilteredStaff);
  }, [staffFromAPI, staff, setFilteredStaff]);

  useEffect(() => {
    setFilteredStaffIds(filteredStaff.map((staffMember) => staffMember.id));
  }, [filteredStaff]);

  const handleStaffChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempArray = [...filteredStaff];
    if (filteredStaffIds.includes(event.target.name)) {
      const index = tempArray.map((homeroom) => homeroom.id).indexOf(event.target.name);
      tempArray.splice(index, 1);
    } else {
      const index = staff.map((staffMember) => staffMember.id).indexOf(event.target.name);
      tempArray.push(staff[index]);
    }
    setFilteredStaff(tempArray);
  };

  const handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const staffFromAPIInDepartment = staffFromAPI.filter(
      (staffMember) => staffMember.department === event.target.name
    );
    const staffFromAPIInDepartmentEmails = staffFromAPIInDepartment.map(
      (staffMember) => staffMember.email
    );
    const staffInDepartment = staff.filter((staffMember) =>
      staffFromAPIInDepartmentEmails.includes(staffMember.email)
    );
    const staffInDepartmentIds = staffInDepartment.map((staffMember) => staffMember.id);

    const tempStaffArray = [...filteredStaff];
    const tempDepartments = [...selectedDepartments];
    if (tempDepartments.includes(event.target.name)) {
      const index = tempDepartments.indexOf(event.target.name);
      tempDepartments.splice(index, 1);
      const filteredArray = tempStaffArray.filter(
        (staffMember) => !staffInDepartmentIds.includes(staffMember.id)
      );
      setFilteredStaff(filteredArray);
    } else {
      tempDepartments.push(event.target.name);
      staff.forEach((staffMember) => {
        if (staffInDepartmentIds.includes(staffMember.id)) {
          tempStaffArray.push(staffMember);
        }
        setFilteredStaff(tempStaffArray);
      });
    }

    setSelectedDepartments(tempDepartments);
  };

  const handleDeselectAll = () => {
    setFilteredStaff([]);
    setSelectedDepartments([]);
  };

  const handleAccordionChange = (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => {
    setClickReady(!expanded);
  };
  return (
    <Box sx={{ mb: 3, pl: 4, pr: 4 }}>
      <Accordion
        sx={{
          border: "none",
          mt: 2,
          "&:before": {
            display: "none",
          },
        }}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Filter Staff</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {staff &&
              staff.map((staffMember) => (
                <Grid key={staffMember.id} item xs={6} sm={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleStaffChange}
                        name={staffMember.id}
                        checked={filteredStaffIds.includes(staffMember.id)}
                      />
                    }
                    label={
                      <Typography className="filterLabel">
                        {staffMember.firstName} {staffMember.lastName}
                      </Typography>
                    }
                  />
                </Grid>
              ))}
          </Grid>
          <Divider />
          <Grid container>
            {departments &&
              departments.map((department) => (
                <Grid key={department} item xs={6} sm={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleDepartmentChange}
                        name={department}
                        checked={selectedDepartments.includes(department)}
                      />
                    }
                    label={<Typography className="filterLabel">{department}</Typography>}
                  />
                </Grid>
              ))}
          </Grid>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button color="secondary" variant="contained" onClick={handleDeselectAll}>
              Deselect All
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
