import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { homeroomsAtom } from "../../recoil/atoms";
import { periodsAtom } from "../../recoil/periodAtoms";
import { PeriodRecord } from "../../types/types";
import useAddDoc from "../../hooks/useAddDoc";

type Props = {
  selectedHomeroomId: string;
};

function ScheduleDuplicator({ selectedHomeroomId }: Props) {
  const homerooms = useRecoilValue(homeroomsAtom);
  const periods = useRecoilValue(periodsAtom);
  const [homeroomToDuplicateId, setHomeroomToDuplicateId] = useState<string | null>(null);
  const { sendRequest: addDoc } = useAddDoc();

  const handleDuplicate = () => {
    if (!homeroomToDuplicateId) return;
    const filteredPeriods = periods.filter((period) => period.homeroomId === homeroomToDuplicateId);
    filteredPeriods.forEach((period) => {
      addDoc({
        col: "periods",
        data: {
          homeroomId: selectedHomeroomId,
          label: period.label,
          startTime: period.startTime,
          endTime: period.endTime,
          sort: period.sort,
        },
      });
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setHomeroomToDuplicateId(event.target.value);
  };
  return (
    <Grid sx={{ mt: 2 }} container spacing={2}>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="selected-homeroom">Select Class</InputLabel>
          <Select
            value={homeroomToDuplicateId ? homeroomToDuplicateId : undefined}
            labelId="selected-homeroom"
            required
            label="Select Class"
            onChange={handleSelectChange}
          >
            {homerooms &&
              homerooms.map((homeroom) => (
                <MenuItem key={homeroom.id} value={homeroom.id}>
                  {homeroom.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={2}>
        <Button sx={{ height: "100%" }} variant="contained" fullWidth onClick={handleDuplicate}>
          Duplicate
        </Button>
      </Grid>
    </Grid>
  );
}

export default ScheduleDuplicator;
