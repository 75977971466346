import { Box, Button, Container, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { periodsAtom, periodsResetAtom } from "../../recoil/periodAtoms";
import Period from "./Period";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import useAddDoc from "../../hooks/useAddDoc";
import DeleteDialog from "../shared/DeleteDialog";
import { PeriodRecord } from "../../types/types";
import ScheduleDuplicator from "./ScheduleDuplicator";
import EditPeriodDialog from "./EditPeriodDialog";

type Props = {
  selectedHomeroomId: string;
};

function ScheduleList({ selectedHomeroomId }: Props) {
  const periods = useRecoilValue(periodsAtom);
  const [filteredPeriods, setFilteredPeriods] = useState<PeriodRecord[]>([]);
  const [startTime, setStartTime] = useState<DateTime | null>(DateTime.fromFormat("7:30 AM", "t"));
  const [endTime, setEndTime] = useState<DateTime | null>(DateTime.fromFormat("7:35 AM", "t"));
  const [label, setLabel] = useState("");
  const setPeriodsReset = useSetRecoilState(periodsResetAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const [periodToDeleteId, setPeriodToDeleteId] = useState<string | null>(null);
  const firstRef = useRef<HTMLInputElement>(null);
  const [periodToEdit, setPeriodToEdit] = useState<PeriodRecord | null>(null);

  useEffect(() => {
    if (!periods) return;
    const tempPeriods = periods.filter((period) => selectedHomeroomId === period.homeroomId);
    tempPeriods.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setFilteredPeriods(tempPeriods);
  }, [periods, selectedHomeroomId]);

  const handleStartChange = (event: DateTime | null) => {
    setStartTime(event);
  };

  const handleEndTime = (event: DateTime | null) => {
    setEndTime(event);
  };

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const handleSubmit = async () => {
    if (!startTime || !endTime) return;
    await addDoc({
      col: "periods",
      data: {
        homeroomId: selectedHomeroomId,
        label,
        startTime: startTime.toFormat("t"),
        endTime: endTime.toFormat("t"),
        sort: startTime.hour * 60 + startTime.minute,
      },
    });
    setLabel("");
    setStartTime(endTime);
    setPeriodsReset((pV) => !pV);
    if (firstRef.current) {
      firstRef.current.focus();
      console.log("focus");
    }
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Typography textAlign={"center"} variant="h5">
          Periods
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          {filteredPeriods.map((period) => (
            <Period
              setPeriodToDeleteId={setPeriodToDeleteId}
              setPeriodToEdit={setPeriodToEdit}
              key={period.id}
              period={period}
            />
          ))}
        </Box>
        <Typography textAlign={"center"} variant="h5">
          Create New Period
        </Typography>
        <Grid sx={{ mt: 2 }} container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              ref={firstRef}
              fullWidth
              label="Period Name"
              value={label}
              onChange={handleLabelChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimeField
                fullWidth
                label="Select Start Time"
                value={startTime}
                onChange={handleStartChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimeField
                fullWidth
                label="Select End Time"
                value={endTime}
                onChange={handleEndTime}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button sx={{ height: "100%" }} variant="contained" fullWidth onClick={handleSubmit}>
              Add
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 4 }} />
        <Box sx={{ mt: 2 }}>
          <ScheduleDuplicator selectedHomeroomId={selectedHomeroomId} />
        </Box>
      </Container>
      {periodToDeleteId && (
        <DeleteDialog
          setReset={setPeriodsReset}
          setId={setPeriodToDeleteId}
          id={periodToDeleteId}
          collection="periods"
          message="Are you sure you want to delete this period?"
        />
      )}
      {periodToEdit && (
        <EditPeriodDialog periodToEdit={periodToEdit} setPeriodToEdit={setPeriodToEdit} />
      )}
    </>
  );
}

export default ScheduleList;
