import React, { useState } from "react";
import { Homeroom, Staff } from "../../types/types";
import { useRecoilValue } from "recoil";
import { staffObjAtom } from "../../recoil/staffAtoms";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Typography } from "@mui/material";
import StaffMenu from "./StaffMenu";

type Props = {
  timeWidth: number;
  columnWidth: number;
  filteredStaff: Staff[];
};

function StaffLabels({ timeWidth, columnWidth, filteredStaff }: Props) {
  const staffObj = useRecoilValue(staffObjAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStaffMemberId, setSelectedStaffMemberId] = useState<string | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setSelectedStaffMemberId(event.currentTarget.name);
  };

  return (
    <>
      <div style={{ marginTop: "16px" }}>
        <div
          id="label-scroll"
          style={{
            position: "relative",
            overflow: "scroll",
            width: "100VW",
            height: "80px",
            overflowX: "hidden",
          }}
        >
          {filteredStaff.map((staffMember, index) => (
            <div
              key={staffMember.id}
              style={{
                position: "absolute",
                top: 0,
                left: timeWidth + columnWidth * index,
                textAlign: "center",
                fontSize: "12px",
                width: `${columnWidth}px`,
                borderLeft: "1px solid #999",
              }}
            >
              <IconButton onClick={handleClick} name={staffMember.id}>
                <MenuIcon />
              </IconButton>
              <div>
                {staffMember.firstName} {staffMember.lastName}
              </div>
            </div>
          ))}
        </div>
      </div>
      <StaffMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedStaffMemberId={selectedStaffMemberId}
      />
    </>
  );
}

export default StaffLabels;
