import { atom, selector } from "recoil";
import { Homeroom, Student } from "../types/types";
import { GetRecoilValue } from "recoil";
type Props = {
  get: GetRecoilValue;
};

const createObjGetter = ({ get }: Props) => {
  const homerooms = get(homeroomsAtom);
  if (!homerooms) return null;
  const tempObj: { [key: string]: Homeroom } = {};
  homerooms.forEach((homeroom) => {
    tempObj[homeroom.id] = homeroom;
  });
  return tempObj;
};

//****** HOMEROOM ATOMS ******//

export const homeroomsAtom = atom<Homeroom[]>({
  key: "homerooms",
  default: [],
});

//****** STUDENT ATOMS ******//

export const studentsAtom = atom<Student[]>({
  key: "students",
  default: [],
});

export const homeroomObjAtom = selector({
  get: createObjGetter,
  key: "homeroomObj",
});
