import React, { useEffect, useState } from "react";
import { Staff, TimeAssignmentRecord } from "../../types/types";
import { useRecoilValue } from "recoil";
import { DateTime, Interval } from "luxon";
import { timeAssignmentsAtoms } from "../../recoil/timeAssignmentAtoms";
import { homeroomObjAtom } from "../../recoil/atoms";

type Props = {
  rowHeight: number;
  left: number;
  staffMember: Staff;
  startTime: string;
  timeInterval: number;
  columnWidth: number;
};

function StaffShift({ rowHeight, left, staffMember, startTime, timeInterval, columnWidth }: Props) {
  const timeAssignments = useRecoilValue(timeAssignmentsAtoms);
  const homerommObj = useRecoilValue(homeroomObjAtom);
  const [filteredTimeAssignments, setFilteredTimeAssignments] = useState<TimeAssignmentRecord[]>(
    []
  );

  useEffect(() => {
    if (!timeAssignmentsAtoms) return;
    const tempTimeAssignments = timeAssignments.filter(
      (timeAssignment) => timeAssignment.staffId === staffMember.id
    );
    setFilteredTimeAssignments(tempTimeAssignments);
  }, [timeAssignments, staffMember]);

  const calcTop = (periodStart: string) => {
    const dtPeriodStart = DateTime.fromFormat(periodStart, "t");
    const dtStartTime = DateTime.fromFormat(startTime, "t");
    const diff = Interval.fromDateTimes(dtStartTime, dtPeriodStart);
    return Math.round((diff.length("minutes") / timeInterval) * rowHeight);
  };

  const calcHeight = (periodStart: string, periodEnd: string) => {
    const dtPeriodStart = DateTime.fromFormat(periodStart, "t");
    const dtPeriodEnd = DateTime.fromFormat(periodEnd, "t");
    const diff = Interval.fromDateTimes(dtPeriodStart, dtPeriodEnd);
    return Math.round((diff.length("minutes") / timeInterval) * rowHeight);
  };

  return (
    <>
      {filteredTimeAssignments.map((timeAssignment) => (
        <div
          key={Math.random()}
          style={{
            position: "absolute",
            left: left - 200,
            zIndex: 100,
            top: calcTop(timeAssignment.startTime),
            width: "100px",
            height: calcHeight(timeAssignment.startTime, timeAssignment.endTime),
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(185, 48, 230, .8)",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 0 1px #333",
              color: "#fff",
              height: "100%",
              borderRadius: "5px",
              width: "100%",
            }}
          >
            {homerommObj && homerommObj[timeAssignment.homeroomId] && (
              <div>{homerommObj[timeAssignment.homeroomId].name}</div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default StaffShift;
