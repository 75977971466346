import React from "react";
import { Homeroom } from "../../types/types";
import { useRecoilValue } from "recoil";
import { staffObjAtom } from "../../recoil/staffAtoms";

type Props = {
  timeWidth: number;
  selectedScheduleWidth: number;
  columnWidth: number;
  pageWidth: number;
  filteredHomerooms: Homeroom[];
  selectedStaffId: string | null;
  source: "by-class" | "my-schedule";
};

function ClassLabels({
  timeWidth,
  selectedScheduleWidth,
  columnWidth,
  filteredHomerooms,
  pageWidth,
  selectedStaffId,
  source,
}: Props) {
  const staffObj = useRecoilValue(staffObjAtom);
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          position: "relative",
          height: "40px",
          width: `${selectedScheduleWidth}px`,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            textAlign: "center",
            width: `${timeWidth}px`,
            height: "40px",
            backgroundColor: "#f9f9f9",
            zIndex: 5,
          }}
        ></div>
        {staffObj && selectedStaffId && staffObj[selectedStaffId] && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: timeWidth,
              textAlign: "center",
              width: `${selectedScheduleWidth}px`,
              height: "40px",
              backgroundColor: "#f9f9f9",
              zIndex: 5,
            }}
          >
            {staffObj[selectedStaffId].firstName.slice(0, 1)}{" "}
            {staffObj[selectedStaffId].lastName.slice(0, 8)}
          </div>
        )}
      </div>
      <div
        id="label-scroll"
        style={{
          position: "relative",
          overflow: "scroll",
          width: "100VW",
          height: "40px",
          overflowX: "hidden",
        }}
      >
        {filteredHomerooms.map((filteredHomeroom, index) => (
          <div key={filteredHomeroom.id}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: timeWidth + 6 + columnWidth * index,
                textAlign: "center",
                width: `${columnWidth}px`,
                borderLeft: "1px solid #999",
                fontSize: 12
              }}
            >
              {filteredHomeroom.name} Class
            </div>
            {source === "by-class" && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: 22,
                    left: timeWidth + 6 + columnWidth * index,
                    textAlign: "center",
                    width: `${columnWidth / 3}px`,
                    borderLeft: "1px solid #999",
                    fontSize: 12,
                  }}
                >
                  Staff #1
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 22,
                    left: timeWidth + 6 + columnWidth * index + 100,
                    textAlign: "center",
                    width: `${columnWidth / 3}px`,
                    borderLeft: "1px solid #999",
                    fontSize: 12,
                  }}
                >
                  Staff #2
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 22,
                    left: timeWidth + 6 + columnWidth * index + 200,
                    textAlign: "center",
                    width: `${columnWidth / 3}px`,
                    borderLeft: "1px solid #999",
                    fontSize: 12,
                  }}
                >
                  Schedule
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClassLabels;
