import React, { useEffect, useState } from "react";
import { BreakRecord, Staff } from "../../types/types";

import { useRecoilValue } from "recoil";
import { breaksAtom } from "../../recoil/breakAtoms";
import ShiftBreak from "./ShiftBreak";

type Props = {
  rowHeight: number;
  columnWidth: number;
  startTime: string;
  timeInterval: number;
  staffMember: Staff;
  staff: Staff[];
  setShiftBreakToEdit?: (pV: BreakRecord | null) => void;
  page: "by-class" | "by-staff" | "my-schedule";
};

const StaffMemberBreaks = ({
  rowHeight,
  columnWidth,
  startTime,
  timeInterval,
  staffMember,
  staff,
  setShiftBreakToEdit,
  page,
}: Props) => {
  const breaks = useRecoilValue(breaksAtom);
  const [filteredBreaks, setFilteredBreaks] = useState<BreakRecord[]>([]);

  useEffect(() => {
    if (!breaks) return;
    const tempBreaks = breaks.filter((shiftBreak) => shiftBreak.staffId === staffMember.id);
    setFilteredBreaks(tempBreaks);
  }, [breaks, staffMember]);

  return (
    <>
      {filteredBreaks.map((filteredBreak) => (
        <ShiftBreak
          rowHeight={rowHeight}
          columnWidth={columnWidth}
          staffMember={staffMember}
          startTime={startTime}
          timeInterval={timeInterval}
          key={filteredBreak.id}
          staff={staff}
          shiftBreak={filteredBreak}
          setShiftBreakToEdit={setShiftBreakToEdit}
          page={page}
        />
      ))}
    </>
  );
};

export default StaffMemberBreaks;
