export enum PERMISSION {
  EDIT_STAFF = "Edit Staff",
  EDIT_SETTINGS = "Edit Settings",
  SEE_ALL_ROASTER = "See All Roasters",
}

export const PERMISSIONS: Array<PERMISSION> = [
  PERMISSION.EDIT_STAFF,
  PERMISSION.EDIT_SETTINGS,
  PERMISSION.SEE_ALL_ROASTER,
];

export enum SettingsSections {
  PERIODS = "Narrative Periods",
  SECTIONS = "Narrative Sections",
  OBJECTIVES = "Narrative Objectives",
  OVERRIDE = "Roster Override",
}

export enum COLLECTION {
  daysPerWeek = "daysPerWeek",
  incidentTypes = "incidentTypes",
  reports = "reports",
  staff = "staff",
}

const aeColor = "#777"
const opsColor = "#555"

export const SCHEDULE_COLORS: { [key: string]: { backgroundColor: string; color: string } } = {
  Recess: { backgroundColor: "#ddd", color: "#333" },
  "Morning Circle": { backgroundColor: "#ccc", color: "#333" },
  Literacy: { backgroundColor: "#aaa", color: "#fff" },
  Math: { backgroundColor: "#999", color: "#fff" },
  "Project Work Time": { backgroundColor: "#888", color: "#fff" },
  Breakfast: { backgroundColor: opsColor, color: "#fff" },
  Snack: { backgroundColor: opsColor, color: "#fff" },
  Lunch: { backgroundColor: opsColor, color: "#fff" },
  "Dismissal Prep": { backgroundColor: opsColor, color: "#fff" },
  Dismissal: { backgroundColor: opsColor, color: "#fff" },
  Music: { backgroundColor: aeColor, color: "#fff" },
  "Digital Fluency": { backgroundColor: aeColor, color: "#fff" },
  Wheelhouse: { backgroundColor: aeColor, color: "#fff" },
  Dance: { backgroundColor: aeColor, color: "#fff" },
  Cooking: { backgroundColor: aeColor, color: "#fff" },
  "Visual Art": { backgroundColor: aeColor, color: "#fff" },
  "Kinder Art": { backgroundColor: aeColor, color: "#fff" },
  "Lit Enrichment": { backgroundColor: aeColor, color: "#fff" },
};

export const COLLECTIONS = Object.values(COLLECTION);
