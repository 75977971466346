import { atom } from "recoil";
import { TimeAssignmentRecord } from "../types/types";

export const timeAssignmentsAtoms = atom<TimeAssignmentRecord[]>({
  key: "timeAssignments",
  default: [],
});

export const timeAssignmentsResetAtom = atom({
  key: "timeAssignmentsReset",
  default: false,
});

export const timeAssignmentTemplatesAtom = atom<TimeAssignmentRecord[]>({
  key: "timeAssignmentTemplates",
  default: [],
});

export const timeAssignmentTemplatesResetAtom = atom({
  key: "timeAssignmentTemplatesReset",
  default: false,
});
