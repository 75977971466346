import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Schedule from "./Schedule";
import TimeSidebar from "../shared/TimeSidebar";
import ScheduleGrid from "../shared/ScheduleGrid";
import TimeAssignment from "./TimeAssignment";
import { useRecoilState, useRecoilValue } from "recoil";
import { DateTime } from "luxon";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  selectedStaffMemberAtom,
  selectedStaffMemberIdAtom,
  staffAtom,
  staffObjAtom,
} from "../../recoil/staffAtoms";
import { timeAssignmentsAtoms } from "../../recoil/timeAssignmentAtoms";
import {
  BreakRecord,
  BreakType,
  TimeAssignmentRecord,
  TimeAssignmentType,
} from "../../types/types";
import Header from "./Header";
import StaffShift from "../ViewSchedule/StaffShift";
import StaffMemberBreaks from "../ViewSchedule/StaffMemberBreaks";
import HomeroomFilter from "./HomeroomFilter";
import ClassLabels from "./ClassLabels";
import EditLabelDialog from "../ViewSchedule/EditLabelDialog";
import { filteredHomeroomsAtom } from "../../recoil/filterAtoms";
import useUpdateRTDoc from "../../hooks/useUpdateRTDoc";
import ConfirmAddDialog from "../shared/ConfirmAddDialog";

function SchedulerContainer() {
  const [pageHeight, setPageHeight] = useState(10000);
  const [pageWidth, setPageWidth] = useState(10000);
  const [rows, setRows] = useState<number[]>([]);
  const containerRef = useRef(null);
  const timeInterval = 5;
  const startTime = "7:30 AM";
  const totalMinutes = 9 * 60;
  const periodInterval = 5; //minutes
  const timeWidth = 200;
  const rowHeight = 25;
  const columnWidth = 300;
  const selectedScheduleWidth = 100;
  const filteredHomerooms = useRecoilValue(filteredHomeroomsAtom);
  const [timeAssignments, setTimeAssignments] = useRecoilState(timeAssignmentsAtoms);
  const staff = useRecoilValue(staffAtom);
  const staffObj = useRecoilValue(staffObjAtom);
  const [shiftBreakToEdit, setShiftBreakToEdit] = useState<BreakRecord | null>(null);
  const selectedStaffMemberId = useRecoilValue(selectedStaffMemberIdAtom);
  const selectedStaffMember = useRecoilValue(selectedStaffMemberAtom);
  const { sendRequest: updateRTDoc } = useUpdateRTDoc();
  const [clickReady, setClickReady] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [newTimeElementData, setNewTimeElementData] = useState<
    BreakType | TimeAssignmentType | null
  >(null);
  const [newTimeElementPath, setNewTimeElementPath] = useState<string | null>(null);
  const [confirmMessage, setConfirmMessage] = useState("");

  useEffect(() => {
    const tempRows: number[] = [];
    const neededRows = Math.ceil(totalMinutes / periodInterval);
    setPageHeight(neededRows * rowHeight);
    for (let i = 0; i < neededRows * rowHeight; i = i + rowHeight) {
      tempRows.push(i);
    }
    setRows(tempRows);
  }, [rowHeight, totalMinutes]);

  useEffect(() => {
    setPageWidth(filteredHomerooms.length * columnWidth);
  }, [filteredHomerooms, columnWidth]);

  const confirmNewTimeElement = useCallback(
    async (event: MouseEvent) => {
      if (event.pageY < 290) return;
      if (!clickReady) return;
      if (!containerRef.current) return;
      if (!selectedStaffMemberId) return;
      const { offsetLeft } = containerRef.current;
      const xPosition = event.clientX - offsetLeft;
      const yPosition = event.offsetY;
      const xScroll = document.getElementById("scroll-x-1")?.offsetParent?.scrollLeft;
      const slot = event.offsetX > columnWidth / 3 ? 1 : 0;
      const dtPeriodStart = DateTime.fromFormat(startTime, "t").plus({
        minutes: Math.floor(yPosition / rowHeight) * 5,
      });
      if (xPosition < 100) {
        if (selectedStaffMemberId) {
          const newBreak: BreakType = {
            staffId: selectedStaffMemberId,
            startTime: dtPeriodStart.toFormat("t"),
            endTime: "8:00AM",
            label: "Break",
          };
          setNewTimeElementData(newBreak);
          setNewTimeElementPath(`breaks/${newBreak.staffId}`);
          setConfirmMessage(
            `Are you sure you want to add a new Break to ${
              staffObj
                ? staffObj[selectedStaffMemberId].firstName +
                  " " +
                  staffObj[selectedStaffMemberId].lastName
                : "User Not Found"
            } at ${dtPeriodStart.toFormat("t")} `
          );
          setConfirmOpen(true);
        }
      } else {
        const newAssignment: TimeAssignmentType = {
          homeroomId:
            filteredHomerooms[Math.floor((xPosition + (xScroll ? xScroll : 0)) / columnWidth)].id,
          slot: slot,
          startTime: dtPeriodStart.toFormat("t"),
          endTime: "8:00AM",
          staffId: selectedStaffMemberId ? selectedStaffMemberId : "",
        };
        setNewTimeElementData(newAssignment);
        setNewTimeElementPath(`timeAssignments/${newAssignment.staffId}`);
        setConfirmMessage(
          `Are you sure you want to add a new Assignment to ${
            staffObj
              ? staffObj[selectedStaffMemberId].firstName +
                " " +
                staffObj[selectedStaffMemberId].lastName
              : "User Not Found"
          } at ${dtPeriodStart.toFormat("t")} `
        );
        setConfirmOpen(true);
      }
    },
    [filteredHomerooms, rowHeight, columnWidth, selectedStaffMemberId, clickReady, staffObj]
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const timeAssignmentId = event.active.id.toLocaleString();
    const oldStartTime = event.active.data.current?.startTime;
    const oldEndTime = event.active.data.current?.endTime;
    const delta = event.delta.y;
    const changeInMinutes = Math.round(delta / rowHeight) * periodInterval;
    const dtStart = DateTime.fromFormat(oldStartTime, "t").plus({ minutes: changeInMinutes });
    const dtEnd = DateTime.fromFormat(oldEndTime, "t").plus({ minutes: changeInMinutes });
    const endTime = dtEnd.toFormat("t");
    const startTime = dtStart.toFormat("t");
    const currentIndex = timeAssignments.findIndex(
      (timeAssignment) => timeAssignment.id === timeAssignmentId
    );
    if (currentIndex !== -1) {
      const mutableArray: TimeAssignmentRecord[] = [...timeAssignments];
      mutableArray.splice(currentIndex, 1);
      mutableArray.push({
        ...timeAssignments[currentIndex],
        startTime: startTime,
        endTime: endTime,
      });
      setTimeAssignments(mutableArray);
    }

    const currentTimeAssignment = timeAssignments.find(
      (timeAssignment) => timeAssignment.id === timeAssignmentId
    );
    if (!currentTimeAssignment) return;
    await updateRTDoc({
      data: { ...currentTimeAssignment, endTime: endTime, startTime: startTime },
      path: `timeAssignments/${currentTimeAssignment.staffId}/${currentTimeAssignment.id}`,
    });
    //setTimeAssignmentsReset((pV) => !pV);
  };

  useEffect(() => {
    if (!filteredHomerooms || filteredHomerooms.length === 0 || !selectedStaffMemberId) return;
    window.addEventListener("dblclick", confirmNewTimeElement);
    return () => {
      window.removeEventListener("dblclick", confirmNewTimeElement);
    };
  }, [filteredHomerooms, confirmNewTimeElement, selectedStaffMemberId]);

  const handleScroll = (event: any) => {
    const labelElement = document.getElementById("label-scroll");
    const scheduleElement = document.getElementById("container-drag-ref");
    if (!labelElement || !scheduleElement) return;
    labelElement.scroll({ left: scheduleElement.scrollLeft });
  };

  return (
    <>
      <Header />
      <HomeroomFilter setClickReady={setClickReady} />
      <ClassLabels
        timeWidth={timeWidth}
        selectedScheduleWidth={selectedScheduleWidth}
        columnWidth={columnWidth}
        filteredHomerooms={filteredHomerooms}
        pageWidth={pageWidth}
        selectedStaffId={selectedStaffMemberId}
        source="by-class"
      />
      {/* Scrolling Container */}
      <Box sx={{ height: "70VH", overflow: "scroll", display: "flex", flexWrap: "nowrap" }}>
        {/* Timebar Container */}
        <div style={{ position: "relative" }}>
          <TimeSidebar
            rowHeight={rowHeight}
            startTime={startTime}
            rows={rows}
            periodInterval={periodInterval}
            timeWidth={timeWidth}
          />
        </div>
        {/* Selected Staff Schedule Container */}
        {selectedStaffMemberId && (
          <div style={{ position: "relative" }}>
            {selectedStaffMember && (
              <div
                id="backdrop-for-shift"
                style={{
                  position: "absolute",
                  zIndex: 100,
                  height: `${pageHeight}px`,
                  width: selectedScheduleWidth,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <StaffShift
                  left={timeWidth}
                  rowHeight={rowHeight}
                  staffMember={selectedStaffMember}
                  startTime={startTime}
                  timeInterval={timeInterval}
                  columnWidth={selectedScheduleWidth}
                />
                <StaffMemberBreaks
                  rowHeight={rowHeight}
                  columnWidth={columnWidth}
                  staffMember={selectedStaffMember}
                  startTime={startTime}
                  timeInterval={timeInterval}
                  staff={staff}
                  page="by-class"
                  setShiftBreakToEdit={setShiftBreakToEdit}
                />
              </div>
            )}
          </div>
        )}
        {/* Assignment and Schedule Container */}
        <div style={{ width: `${pageWidth + timeWidth + selectedScheduleWidth}px` }}>
          <div
            onScroll={handleScroll}
            style={{
              position: "relative",
              overflowX: "scroll",
              height: `${pageHeight}px`,
            }}
            id="container-drag-ref"
            ref={containerRef}
          >
            <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
              <>
                <ScheduleGrid
                  rowHeight={rowHeight}
                  columnWidth={columnWidth}
                  pageWidth={pageWidth}
                  pageHeight={pageHeight}
                  firstColumnWidth={selectedScheduleWidth}
                />
                {timeAssignments.map((timeAssignment, index) => (
                  <TimeAssignment
                    rowHeight={rowHeight}
                    columnWidth={columnWidth}
                    timeAssignment={timeAssignment}
                    startTime={startTime}
                    timeInterval={timeInterval}
                    homerooms={filteredHomerooms}
                    key={timeAssignment.homeroomId + index}
                    initialLeft={columnWidth * index + timeWidth + selectedScheduleWidth}
                  />
                ))}
                {filteredHomerooms &&
                  filteredHomerooms.map((homeroom, index) => (
                    <Schedule
                      key={homeroom.id}
                      left={columnWidth * index + timeWidth + selectedScheduleWidth}
                      rowHeight={rowHeight}
                      homeroom={homeroom}
                      startTime={startTime}
                      timeInterval={timeInterval}
                      columnWidth={columnWidth}
                    />
                  ))}
              </>
            </DndContext>
          </div>
        </div>
      </Box>
      {shiftBreakToEdit && (
        <EditLabelDialog
          shiftBreakToEdit={shiftBreakToEdit}
          setShiftBreakToEdit={setShiftBreakToEdit}
          setClickReady={setClickReady}
        />
      )}
      {confirmOpen && (
        <ConfirmAddDialog
          open={confirmOpen}
          setOpen={setConfirmOpen}
          message={confirmMessage}
          data={newTimeElementData}
          path={newTimeElementPath}
        />
      )}
    </>
  );
}

export default SchedulerContainer;
