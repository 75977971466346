import { Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import MyScheduleContainer from "../components/MySchedule/MyScheduleContainer";

const MySchedulePage = () => {
  return (
    <>
      <Navbar />
      <div style={{ minHeight: "calc(100vh-64px)", marginTop: "64px", paddingTop: "16px" }}>
        <MyScheduleContainer />
      </div>
    </>
  );
};

export default MySchedulePage;
