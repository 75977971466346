import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { homeroomsAtom } from "../../recoil/atoms";
import { Homeroom } from "../../types/types";

type Props = {
  selectedHomeroomId: string | null;
  setSelectedHomeroomId: (value: string) => void;
};

function Header({ selectedHomeroomId, setSelectedHomeroomId }: Props) {
  const homerooms = useRecoilValue(homeroomsAtom);
  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedHomeroomId(event.target.value);
  };

  useEffect(() => {
    if (!homerooms || homerooms.length === 0) return;
    if (!selectedHomeroomId) {
      setSelectedHomeroomId(homerooms[0].id);
    }
  }, [homerooms, setSelectedHomeroomId, selectedHomeroomId]);
  return (
    <>
      {selectedHomeroomId && (
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} sm={9}>
            <Typography variant="h2">Class Schedule Creator</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="selected-homeroom">Select Class</InputLabel>
              <Select
                value={selectedHomeroomId}
                labelId="selected-homeroom"
                required
                label="Select Class"
                onChange={handleSelectChange}
              >
                {homerooms &&
                  homerooms.map((homeroom) => (
                    <MenuItem key={homeroom.id} value={homeroom.id}>
                      {homeroom.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Header;
