import { atom } from "recoil";
import { BreakRecord } from "../types/types";

export const breaksAtom = atom<BreakRecord[]>({
  key: "breaks",
  default: [],
});

export const breaksResetAtom = atom({
  key: "breaksReset",
  default: false,
});

export const breakTemplatesAtom = atom<BreakRecord[]>({
  key: "breakTemplates",
  default: [],
});

export const breakTemplatesResetAtom = atom({
  key: "breakTemplatesReset",
  default: false,
});
