import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { BreakType, TimeAssignmentType } from "../../types/types";
import useAddRTDoc from "../../hooks/useAddRTDoc";

type Props = {
  message: string;
  open: boolean;
  setOpen: (pV: boolean) => void;
  data: BreakType | TimeAssignmentType | null;
  path: string | null;
};

export default function ConfirmAddDialog({ message, data, path, open, setOpen }: Props) {
  const { sendRequest: addRTDoc } = useAddRTDoc();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    if (!data || !path) return;
    addRTDoc({ data, path });
    setOpen(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleConfirm();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle>Add Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
