import React, { useEffect, useState } from "react";
import { Homeroom } from "../../types/types";
import { useRecoilValue } from "recoil";
import { periodsAtom } from "../../recoil/periodAtoms";
import { PeriodRecord } from "../../types/types";
import { DateTime, Interval } from "luxon";
import { SCHEDULE_COLORS } from "../../libraries/objects";

type Props = {
  rowHeight: number;
  left: number;
  homeroom: Homeroom;
  startTime: string;
  timeInterval: number;
  columnWidth: number;
};

function Schedule({ rowHeight, left, homeroom, startTime, timeInterval }: Props) {
  const periods = useRecoilValue(periodsAtom);
  const [filteredPeriods, setFilteredPeriods] = useState<PeriodRecord[]>([]);

  useEffect(() => {
    if (!periods) return;
    setFilteredPeriods(periods.filter((period) => period.homeroomId === homeroom.id));
  }, [periods, homeroom]);

  const calcTop = (periodStart: string) => {
    const dtPeriodStart = DateTime.fromFormat(periodStart, "t");
    const dtStartTime = DateTime.fromFormat(startTime, "t");
    const diff = Interval.fromDateTimes(dtStartTime, dtPeriodStart);
    return Math.round((diff.length("minutes") / timeInterval) * rowHeight);
  };

  const calcHeight = (periodStart: string, periodEnd: string) => {
    const dtPeriodStart = DateTime.fromFormat(periodStart, "t");
    const dtPeriodEnd = DateTime.fromFormat(periodEnd, "t");
    const diff = Interval.fromDateTimes(dtPeriodStart, dtPeriodEnd);
    return Math.round((diff.length("minutes") / timeInterval) * rowHeight);
  };

  return (
    <>
      {filteredPeriods.map((period) => (
        <div
          key={Math.random()}
          style={{
            position: "absolute",
            left: left,
            zIndex: 99,
            top: calcTop(period.startTime),
            width: "100px",
            height: calcHeight(period.startTime, period.endTime),
          }}
        >
          <div
            style={{
              backgroundColor: SCHEDULE_COLORS[period.label] ? SCHEDULE_COLORS[period.label].backgroundColor : "#333",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 0 1px #333",
              color: SCHEDULE_COLORS[period.label] ? SCHEDULE_COLORS[period.label].color : "#fff",
              height: "100%",
              borderRadius: "5px",
              width: "100%",
            }}
          >
            <div>{period.label}</div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Schedule;
