import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import TimeSidebar from "../shared/TimeSidebar";
import ScheduleGrid from "../shared/ScheduleGrid";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { useRecoilState, useRecoilValue } from "recoil";
import StaffShift from "../ViewSchedule/StaffShift";
import { Staff } from "../../types/types";
import StaffMemberBreaks from "../ViewSchedule/StaffMemberBreaks";
import HomeroomFilter from "../Scheduler/HomeroomFilter";
import ClassLabels from "../Scheduler/ClassLabels";
import Schedule from "../Scheduler/Schedule";
import Header from "./Header";
import { filteredHomeroomsAtom } from "../../recoil/filterAtoms";

function MyScheduleContainer() {
  const [rowHeight, setRowHeight] = useState(25);
  const [columnWidth, setColumnWidth] = useState(100);
  const [pageHeight, setPageHeight] = useState(10000);
  const [pageWidth, setPageWidth] = useState(10000);
  const [rows, setRows] = useState<number[]>([]);
  const containerRef = useRef(null);
  const timeInterval = 5;
  const startTime = "7:30 AM";
  const totalMinutes = 9 * 60;
  const periodInterval = 5; //minutes
  const timeWidth = 200;
  const selectedScheduleWidth = 100;
  const [filteredStaff, setFilteredStaff] = useState<Staff[]>([]);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const filteredHomerooms = useRecoilValue(filteredHomeroomsAtom);
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);

  useEffect(() => {
    if (!loggedInStaff) return;
    setSelectedStaff(loggedInStaff);
  }, [loggedInStaff]);

  useEffect(() => {
    const tempRows: number[] = [];
    const neededRows = Math.ceil(totalMinutes / periodInterval);
    setPageHeight(neededRows * rowHeight);
    for (let i = 0; i < neededRows * rowHeight; i = i + rowHeight) {
      tempRows.push(i);
    }
    setRows(tempRows);
  }, [rowHeight, totalMinutes]);

  useEffect(() => {
    if (!filteredHomerooms) return;
    setPageWidth(filteredHomerooms.length * columnWidth);
    console.log(filteredHomerooms.length * columnWidth);
  }, [filteredHomerooms, columnWidth]);

  const handleScroll = (event: any) => {
    const labelElement = document.getElementById("label-scroll");
    const scheduleElement = document.getElementById("container-drag-ref");
    if (!labelElement || !scheduleElement) return;
    labelElement.scroll({ left: scheduleElement.scrollLeft });
  };

  return (
    <>
      {selectedStaff && (
        <Box>
          {loggedInStaff && loggedInStaff.email === "ryan@thegatheringplacek12.org" && (
            <Header selectedStaff={selectedStaff} setSelectedStaff={setSelectedStaff} />
          )}
          <HomeroomFilter />
          <ClassLabels
            timeWidth={timeWidth}
            selectedScheduleWidth={selectedScheduleWidth}
            columnWidth={columnWidth}
            filteredHomerooms={filteredHomerooms}
            pageWidth={pageWidth}
            selectedStaffId={selectedStaff.id}
            source="my-schedule"
          />
          <Box sx={{ height: "80VH", overflow: "scroll", display: "flex", flexWrap: "nowrap" }}>
            <div style={{ width: `${timeWidth}px` }}>
              <div style={{ position: "relative" }}>
                <TimeSidebar
                  rowHeight={rowHeight}
                  startTime={startTime}
                  rows={rows}
                  periodInterval={periodInterval}
                  timeWidth={timeWidth}
                />
              </div>
            </div>
            <div style={{ width: `${pageWidth + timeWidth + selectedScheduleWidth}px` }}>
              <div
                onScroll={handleScroll}
                style={{
                  position: "relative",
                  overflowX: "scroll",
                  height: `${pageHeight}px`,
                }}
                id="container-drag-ref"
                ref={containerRef}
              >
                <>
                  <ScheduleGrid
                    rowHeight={rowHeight}
                    columnWidth={columnWidth}
                    pageWidth={pageWidth}
                    pageHeight={pageHeight}
                    firstColumnWidth={0}
                  />

                  <StaffShift
                    left={timeWidth}
                    rowHeight={rowHeight}
                    staffMember={selectedStaff}
                    startTime={startTime}
                    timeInterval={timeInterval}
                    columnWidth={columnWidth}
                  />
                  <StaffMemberBreaks
                    rowHeight={rowHeight}
                    columnWidth={columnWidth}
                    staffMember={selectedStaff}
                    startTime={startTime}
                    timeInterval={timeInterval}
                    staff={filteredStaff}
                    page="my-schedule"
                  />
                  {filteredHomerooms &&
                    filteredHomerooms.map((homeroom, index) => (
                      <Schedule
                        key={homeroom.id}
                        left={columnWidth * index + selectedScheduleWidth}
                        rowHeight={rowHeight}
                        homeroom={homeroom}
                        startTime={startTime}
                        timeInterval={timeInterval}
                        columnWidth={columnWidth}
                      />
                    ))}
                </>
              </div>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
}

export default MyScheduleContainer;
