import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";

type Props = {
  rowHeight: number;
  startTime: string;
  rows: number[];
  periodInterval: number;
  timeWidth: number;
};

function TimeSidebar({ rowHeight, startTime, rows, periodInterval, timeWidth }: Props) {
  const generateTime = (row: number) => {
    const minutesToAdd = Math.round(row / rowHeight) * periodInterval;
    const dtStartTime = DateTime.fromFormat(startTime, "t").plus({
      minutes: minutesToAdd,
    });
    const dtEndTime = DateTime.fromFormat(startTime, "t").plus({
      minutes: minutesToAdd + 5,
    });
    return `${dtStartTime.toFormat("t")} - ${dtEndTime.toFormat("t")}`;
  };
  return (
    <>
      <div style={{ textAlign: "center", width: `${timeWidth}px` }}>
        {rows.map((row) => (
          <div
            key={row}
            style={{
              boxSizing: "border-box",
              position: "absolute",
              width: "100%",
              left: 0,
              top: row,
              zIndex: 1,
              borderTop: "1px solid #ccc",
            }}
          >
            {generateTime(row)}
          </div>
        ))}
      </div>
    </>
  );
}

export default TimeSidebar;
