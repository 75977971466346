import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { homeroomsAtom } from "../../recoil/atoms";
import { useEffect, useState } from "react";
import { filteredHomeroomsAtom } from "../../recoil/filterAtoms";
import { Homeroom } from "../../types/types";

type GroupedHomerooms = {
  kindergarten: Homeroom[];
  lowerElementary: Homeroom[];
  upperElementary: Homeroom[];
  sped: Homeroom[];
};

type Props = {
  setClickReady?: (pV: boolean) => void;
};

export default function HomeroomFilter({ setClickReady }: Props) {
  const homerooms = useRecoilValue(homeroomsAtom);
  const [filteredHomerooms, setFilteredHomerooms] = useRecoilState(filteredHomeroomsAtom);
  const [filteredHomeroomIds, setFilteredHomeroomIds] = useState<string[]>([]);
  const [groupedHomerooms, setGroupedHomerooms] = useState<GroupedHomerooms>({
    kindergarten: [],
    lowerElementary: [],
    upperElementary: [],
    sped: [],
  });

  useEffect(() => {
    if (!homerooms) return;
    setGroupedHomerooms((pV) => {
      return { ...pV, kindergarten: homerooms.filter((hr) => hr.grade === "Kindergarten") };
    });
    setGroupedHomerooms((pV) => {
      return { ...pV, lowerElementary: homerooms.filter((hr) => hr.grade === "Lower Elementary") };
    });
    setGroupedHomerooms((pV) => {
      return { ...pV, upperElementary: homerooms.filter((hr) => hr.grade === "Upper Elementary") };
    });
    setGroupedHomerooms((pV) => {
      return { ...pV, sped: homerooms.filter((hr) => hr.grade === "") };
    });
  }, [homerooms]);

  useEffect(() => {
    setFilteredHomeroomIds(filteredHomerooms.map((homeroom) => homeroom.id));
  }, [filteredHomerooms]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempArray = [...filteredHomerooms];
    if (filteredHomeroomIds.includes(event.target.name)) {
      const index = tempArray.map((homeroom) => homeroom.id).indexOf(event.target.name);
      tempArray.splice(index, 1);
    } else {
      const index = homerooms.map((homeroom) => homeroom.id).indexOf(event.target.name);
      tempArray.push(homerooms[index]);
    }
    setFilteredHomerooms(tempArray);
  };

  const handleAccordionChange = (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => {
    if (!setClickReady) return;
    console.log(!expanded, "What Click Ready was set to");
    setClickReady(!expanded);
  };

  const handleSelectAllByGrade = (
    grade: "kindergarten" | "lowerElementary" | "upperElementary" | "sped"
  ) => {
    setFilteredHomerooms((pV) => {
      const newFilteredHomerooms: Homeroom[] = [...pV];
      const previousIds = pV.map((hr) => hr.id);
      groupedHomerooms[grade].forEach((groupedHR) => {
        if (!previousIds.includes(groupedHR.id)) {
          newFilteredHomerooms.push(groupedHR);
        }
      });

      return newFilteredHomerooms;
    });
  };

  const handleDeselectAll = () => {
    setFilteredHomerooms([]);
  };
  return (
    <Box sx={{ mb: 3, pl: 4, pr: 4 }}>
      <Accordion
        sx={{
          border: "none",
          mt: 2,
          "&:before": {
            display: "none",
          },
        }}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Filter Classes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6">Kindergarten</Typography>
            <Button
              sx={{ ml: 1 }}
              variant="outlined"
              onClick={() => handleSelectAllByGrade("kindergarten")}
            >
              Select All
            </Button>
          </Box>
          <Grid container>
            {groupedHomerooms.kindergarten.map((homeroom) => (
              <Grid key={homeroom.id} item xs={6} sm={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name={homeroom.id}
                      checked={filteredHomeroomIds.includes(homeroom.id)}
                    />
                  }
                  label={<Typography className="filterLabel">{homeroom.name}</Typography>}
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6">Lower Elementary</Typography>
            <Button
              sx={{ ml: 1 }}
              variant="outlined"
              onClick={() => handleSelectAllByGrade("lowerElementary")}
            >
              Select All
            </Button>
          </Box>
          <Grid container>
            {groupedHomerooms.lowerElementary.map((homeroom) => (
              <Grid key={homeroom.id} item xs={6} sm={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name={homeroom.id}
                      checked={filteredHomeroomIds.includes(homeroom.id)}
                    />
                  }
                  label={<Typography className="filterLabel">{homeroom.name}</Typography>}
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6">Upper Elementary</Typography>
            <Button
              sx={{ ml: 1 }}
              variant="outlined"
              onClick={() => handleSelectAllByGrade("upperElementary")}
            >
              Select All
            </Button>
          </Box>
          <Grid container>
            {groupedHomerooms.upperElementary.map((homeroom) => (
              <Grid key={homeroom.id} item xs={6} sm={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name={homeroom.id}
                      checked={filteredHomeroomIds.includes(homeroom.id)}
                    />
                  }
                  label={<Typography className="filterLabel">{homeroom.name}</Typography>}
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6">SPED</Typography>
            <Button
              sx={{ ml: 1 }}
              variant="outlined"
              onClick={() => handleSelectAllByGrade("sped")}
            >
              Select All
            </Button>
          </Box>
          <Grid container>
            {groupedHomerooms.sped.map((homeroom) => (
              <Grid key={homeroom.id} item xs={6} sm={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name={homeroom.id}
                      checked={filteredHomeroomIds.includes(homeroom.id)}
                    />
                  }
                  label={<Typography className="filterLabel">{homeroom.name}</Typography>}
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button color="secondary" variant="contained" onClick={handleDeselectAll}>
              Deselect All
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
