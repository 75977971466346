import { Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import ScheduleContainer from "../components/Scheduler/ScheduleContainer";

const SchedulerPage = () => {
  return (
    <>
      <Navbar />
      <div style={{ minHeight: "calc(100vh-64px)", marginTop: "64px", paddingTop: "16px" }}>
        <ScheduleContainer />
      </div>
    </>
  );
};

export default SchedulerPage;
