import React, { useCallback, useEffect, useState } from "react";
import { homeroomsAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

type Props = {
  rowHeight: number;
  columnWidth: number;
  pageWidth: number;
  pageHeight: number;
  firstColumnWidth: number;
};

function ScheduleGrid({ rowHeight, columnWidth, pageWidth, pageHeight, firstColumnWidth }: Props) {
  const [rows, setRows] = useState<number[]>([]);
  const [columns, setColumns] = useState<number[]>([]);
  const [columnSpacing, setColumnSpacing] = useState(5);
  const homerooms = useRecoilValue(homeroomsAtom);

  useEffect(() => {
    if (!homerooms) return;
    const tempRows: number[] = [];
    const totalMinutes = 9 * 60;
    const periodInterval = 5; //minutes
    const neededRows = Math.ceil(totalMinutes / periodInterval);
    for (let i = 0; i < neededRows * rowHeight; i = i + rowHeight) {
      tempRows.push(i);
    }
    setRows(tempRows);
    const tempColumns: number[] = [];
    for (let i = 0; i < pageWidth; i = i + columnWidth) {
      tempColumns.push(i);
    }
    setColumns(tempColumns);
  }, [rowHeight, columnWidth, homerooms, pageWidth]);

  return (
    <>
      <div>
        {rows.map((row) => (
          <div
            key={row}
            style={{
              boxSizing: "border-box",
              position: "absolute",
              width: pageWidth,
              left: 0,
              top: row,
              zIndex: 1,
              borderTop: "1px solid #ccc",
            }}
          ></div>
        ))}
        <div
          style={{
            boxSizing: "border-box",
            position: "absolute",
            width: `${firstColumnWidth}px`,
            height: pageHeight,
            left: 0,
            top: 0,
            zIndex: 1,
            borderLeft: "1px solid #ccc",
          }}
        ></div>
        {columns.map((column, index) => (
          <div
            key={column}
            style={{
              boxSizing: "border-box",
              position: "absolute",
              width: `${columnWidth}px`,
              height: pageHeight,
              left: column + firstColumnWidth,
              top: 0,
              zIndex: 1,
              borderLeft: "1px solid #ccc",
            }}
            id={`scroll-x-${index}`}
          ></div>
        ))}
      </div>
    </>
  );
}

export default ScheduleGrid;
