import { Link } from "react-router-dom";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { useRecoilValue } from "recoil";

function Weblinks() {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  return (
    <>
      {/* can set permissions here */}
      <Link className="webLink" to="/my-schedule">
        My Schedule
      </Link>
      <Link className="webLink" to="/class-schedule-creator">
        Creator
      </Link>
      <Link className="webLink" to="/scheduler">
        By Class
      </Link>
      <Link className="webLink" to="/view-schedule">
        By Staff
      </Link>
      <Link className="webLink" to="/staff">
        Staff
      </Link>
    </>
  );
}

export default Weblinks;
