import {
  Homeroom,
  Student,
  Staff,
  Period,
  PeriodRecord,
  TimeAssignmentRecord,
  BreakRecord,
} from "../types/types";

export const parseHomeroomResponse = (response: Homeroom[]): Homeroom[] =>
  response.map((homeroom: Homeroom) => ({
    id: homeroom?.id ?? "",
    name: homeroom?.name ?? "",
    grade: homeroom?.grade ?? "",
    active: homeroom?.active ?? false,
  }));

export const parseStudentResponse = (response: Student[]): Student[] =>
  response.map((student: Student) => ({
    id: student?.id ?? "",
    childFirstName: student?.childFirstName ?? "",
    childLastName: student?.childLastName ?? "",
    enrollStatus: student?.enrollStatus ?? "",
    homeroom: student?.homeroom ?? "",
    SID: student?.SID ?? "",
  }));

export const parseStaffResponse = (response: Staff[]): Staff[] =>
  response.map((staff: Staff) => ({
    id: staff?.id ?? "",
    firstName: staff?.firstName ?? "",
    lastName: staff?.lastName ?? "",
    email: staff?.email ?? "",
    permissions: staff?.permissions ?? [],
    homerooms: staff?.homerooms ?? [],
  }));

export const parsePeriodsResponse = (response: PeriodRecord[]): PeriodRecord[] =>
  response.map((record: PeriodRecord) => ({
    id: record?.id ?? "",
    homeroomId: record?.homeroomId ?? "",
    label: record?.label ?? "",
    startTime: record?.startTime ?? "",
    endTime: record?.endTime ?? [],
    createdAt: record?.createdAt ?? null,
    lastUpdatedAt: record?.lastUpdatedAt ?? null,
    sort: record?.sort ?? 0,
  }));

export const parseTimeAssignmentsResponse = (
  response: TimeAssignmentRecord[]
): TimeAssignmentRecord[] =>
  response.map((record: TimeAssignmentRecord) => ({
    id: record?.id ?? "",
    homeroomId: record?.homeroomId ?? "",
    staffId: record?.staffId ?? "",
    startTime: record?.startTime ?? "",
    endTime: record?.endTime ?? [],
    createdAt: record?.createdAt ?? null,
    lastUpdatedAt: record?.lastUpdatedAt ?? null,
    slot: record?.slot ?? 0,
  }));

export const parseBreaksResponse = (response: BreakRecord[]): BreakRecord[] =>
  response.map((record: BreakRecord) => ({
    id: record?.id ?? "",
    staffId: record?.staffId ?? "",
    startTime: record?.startTime ?? "",
    label: record?.label ?? "",
    endTime: record?.endTime ?? [],
    createdAt: record?.createdAt ?? null,
    lastUpdatedAt: record?.lastUpdatedAt ?? null,
  }));
