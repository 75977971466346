import React, { useState } from "react";

import Header from "./Header";
import ScheduleList from "./ScheduleList";

function ClassScheduleCreatorContainer() {
  const [selectedHomeroomId, setSelectedHomeroomId] = useState<string | null>(null);
  return (
    <>
      <Header
        selectedHomeroomId={selectedHomeroomId}
        setSelectedHomeroomId={setSelectedHomeroomId}
      />
      {selectedHomeroomId && <ScheduleList selectedHomeroomId={selectedHomeroomId} />}
    </>
  );
}

export default ClassScheduleCreatorContainer;
