import { useState, useCallback } from "react";
import useAddRTDoc from "./useAddRTDoc";
import useDeleteRTDoc from "./useDeleteRTDoc";
import { timeAssignmentTemplatesAtom, timeAssignmentsAtoms } from "../recoil/timeAssignmentAtoms";
import { breakTemplatesAtom, breaksAtom } from "../recoil/breakAtoms";
import { useRecoilValue } from "recoil";
import useDeleteDoc from "./useDeleteDoc";
import useAddDoc from "./useAddDoc";

type GetDocType = {
  updateToDefault: (selectedStaffMemberId: string) => Promise<boolean>;
  setScheduleAsDefault: (selectedStaffMemberId: string) => Promise<boolean>;
  clearSchedule: (selectedStaffMemberId: string) => void;
  loading: boolean;
};

const useTemplates = (): GetDocType => {
  const [loading, setLoading] = useState(false);
  const timeAssignments = useRecoilValue(timeAssignmentsAtoms);
  const breaks = useRecoilValue(breaksAtom);
  const timeAssignmentTemplates = useRecoilValue(timeAssignmentTemplatesAtom);
  const breakTemplates = useRecoilValue(breakTemplatesAtom);
  const { sendRequest: addRTDoc } = useAddRTDoc();
  const { sendRequest: deleteRTDoc } = useDeleteRTDoc();
  const { sendRequest: deleteDoc } = useDeleteDoc();
  const { sendRequest: addDoc } = useAddDoc();

  const filterData = useCallback(
    (selectedStaffMemberId: string) => {
      const filteredTimeAssignmentTemplates = timeAssignmentTemplates.filter(
        (timeAssignmentTemplate) => timeAssignmentTemplate.staffId === selectedStaffMemberId
      );
      const filteredBreakTemplates = breakTemplates.filter(
        (breakTemplate) => breakTemplate.staffId === selectedStaffMemberId
      );
      const filteredBreaks = breaks.filter(
        (shiftBreak) => shiftBreak.staffId === selectedStaffMemberId
      );
      const filteredTimeAssignments = timeAssignments.filter(
        (timeAssignment) => timeAssignment.staffId === selectedStaffMemberId
      );
      return {
        filteredTimeAssignments,
        filteredBreaks,
        filteredTimeAssignmentTemplates,
        filteredBreakTemplates,
      };
    },
    [breakTemplates, breaks, timeAssignmentTemplates, timeAssignments]
  );

  const setScheduleAsDefault = useCallback(
    async (selectedStaffMemberId: string) => {
      setLoading(true);
      const {
        filteredTimeAssignments,
        filteredBreaks,
        filteredTimeAssignmentTemplates,
        filteredBreakTemplates,
      } = filterData(selectedStaffMemberId);
      const promises: Array<Promise<string | null>> = [];
      filteredTimeAssignmentTemplates.forEach((timeAssignmentTemplate) => {
        promises.push(deleteDoc({ col: "timeAssignmentTemplates", id: timeAssignmentTemplate.id }));
      });
      filteredBreakTemplates.forEach((breakTemplate) => {
        promises.push(deleteDoc({ col: "breakTemplates", id: breakTemplate.id }));
      });
      filteredTimeAssignments.forEach((filteredTimeAssignment) => {
        const data: any = { ...filteredTimeAssignment };
        delete data.id;
        delete data.createdAt;
        delete data.lastUpdatedAt;
        promises.push(addDoc({ col: "timeAssignmentTemplates", data: data }));
      });
      filteredBreaks.forEach((shiftBreak) => {
        const data: any = { ...shiftBreak };
        delete data.id;
        delete data.createdAt;
        delete data.lastUpdatedAt;
        promises.push(addDoc({ col: "breakTemplates", data: data }));
      });
      await Promise.all(promises);
      setLoading(false);
      return true;
    },
    [filterData, addDoc, deleteDoc]
  );

  const updateToDefault = async (selectedStaffMemberId: string) => {
    setLoading(true);
    const {
      filteredTimeAssignments,
      filteredBreaks,
      filteredTimeAssignmentTemplates,
      filteredBreakTemplates,
    } = filterData(selectedStaffMemberId);
    const promises: Array<Promise<string | null>> = [];
    filteredTimeAssignments.forEach((timeAssignment) => {
      promises.push(
        deleteRTDoc({ path: `timeAssignments/${timeAssignment.staffId}/${timeAssignment.id}` })
      );
    });
    filteredBreaks.forEach((shiftBreak) => {
      promises.push(deleteRTDoc({ path: `breaks/${shiftBreak.staffId}/${shiftBreak.id}` }));
    });
    filteredTimeAssignmentTemplates.forEach((filteredTimeAssignmentTemplate) => {
      const data: any = { ...filteredTimeAssignmentTemplate };
      delete data.id;
      delete data.createdAt;
      delete data.lastUpdatedAt;
      promises.push(addRTDoc({ path: `timeAssignments/${data.staffId}`, data: data }));
    });
    filteredBreakTemplates.forEach((breakTemplate) => {
      const data: any = { ...breakTemplate };
      delete data.id;
      delete data.createdAt;
      delete data.lastUpdatedAt;
      promises.push(addRTDoc({ path: `breaks/${data.staffId}`, data: data }));
    });
    await Promise.all(promises);
    setLoading(false);
    return true;
  };

  const clearSchedule = async (selectedStaffMemberId: string) => {
    setLoading(true);
    const { filteredTimeAssignments, filteredBreaks } = filterData(selectedStaffMemberId);
    const promises: Array<Promise<string | null>> = [];
    filteredTimeAssignments.forEach((timeAssignment) => {
      promises.push(
        deleteRTDoc({ path: `timeAssignments/${timeAssignment.staffId}/${timeAssignment.id}` })
      );
    });
    filteredBreaks.forEach((shiftBreak) => {
      deleteRTDoc({ path: `breaks/${shiftBreak.staffId}/${shiftBreak.id}` });
    });
    await Promise.all(promises);
    setLoading(false);
  };

  return { loading, updateToDefault, setScheduleAsDefault, clearSchedule };
};

export default useTemplates;
