import { useState, useCallback } from "react";
import { getDatabase, ref, remove } from "firebase/database";

interface SendRequestProps {
  path: string;
}

const useDeleteRTDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async ({ path }: SendRequestProps): Promise<string | null> => {
    setIsLoading(true);
    setError(null);
    try {
      const db = getDatabase();
      await remove(ref(db, path));
      setIsLoading(false);
      return null;
    } catch (err: any) {
      setError(err.message || "Something went wrong!");
      setIsLoading(false);
      return null;
    }
  }, []);
  return { isLoading, error, sendRequest };
};

export default useDeleteRTDoc;
