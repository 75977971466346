import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginContainer from "./components/Login/LoginContainer";
import StaffPage from "./pages/StaffPage";
import CustomRoute from "./CustomRoute";
import { PERMISSION } from "./libraries/objects";
import SchedulerPage from "./pages/SchedulerPage";
import ClassScheduleCreatorPage from "./pages/ClassScheduleCreatorPage";
import ViewSchedulePage from "./pages/ViewSchedulePage";
import MySchedulePage from "./pages/MySchedulePage";

export const routes = (
  <Routes>
    <Route path="/login" element={<LoginContainer />} />
    {/* <Route path="/staff" element={<StaffPage />} /> */}
    <Route
      path="/staff"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <StaffPage />
        </CustomRoute>
      }
    />

    <Route
      path="/scheduler"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <SchedulerPage />
        </CustomRoute>
      }
    />
    <Route
      path="/my-schedule"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <MySchedulePage />
        </CustomRoute>
      }
    />
    <Route
      path="/view-schedule"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <ViewSchedulePage />
        </CustomRoute>
      }
    />
    <Route
      path="/class-schedule-creator"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <ClassScheduleCreatorPage />
        </CustomRoute>
      }
    />
    <Route
      path="/"
      element={
        <CustomRoute permission={PERMISSION.EDIT_STAFF}>
          <StaffPage />
        </CustomRoute>
      }
    />
  </Routes>
);
