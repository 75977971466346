import { Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import ViewScheduleContainer from "../components/ViewSchedule/ViewScheduleContainer";

const ViewSchedulePage = () => {
  return (
    <>
      <Navbar />
      <div style={{ minHeight: "calc(100vh-64px)", marginTop: "64px", paddingTop: "16px" }}>
        <ViewScheduleContainer />
      </div>
    </>
  );
};

export default ViewSchedulePage;
