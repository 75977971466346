import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import TimeSidebar from "../shared/TimeSidebar";
import ScheduleGrid from "../shared/ScheduleGrid";
import { useRecoilState, useRecoilValue } from "recoil";
import StaffShift from "./StaffShift";
import { DateTime } from "luxon";
import { BreakRecord, BreakType, Staff, TimeAssignmentType } from "../../types/types";
import { breaksAtom } from "../../recoil/breakAtoms";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import StaffMemberBreaks from "./StaffMemberBreaks";
import EditLabelDialog from "./EditLabelDialog";
import StaffLabels from "./StaffLabels";
import DepartmentFilter from "./DepartmentFIlter";
import useUpdateRTDoc from "../../hooks/useUpdateRTDoc";
import useAddRTDoc from "../../hooks/useAddRTDoc";
import useTemplates from "../../hooks/useTemplates";
import ConfirmAddDialog from "../shared/ConfirmAddDialog";
import { staffObjAtom } from "../../recoil/staffAtoms";

function ViewScheduleContainer() {
  const [rowHeight, setRowHeight] = useState(25);
  const [columnWidth, setColumnWidth] = useState(100);
  const [pageHeight, setPageHeight] = useState(10000);
  const [pageWidth, setPageWidth] = useState(10000);
  const [rows, setRows] = useState<number[]>([]);
  const [breaks, setBreaks] = useRecoilState(breaksAtom);
  const staffObj = useRecoilValue(staffObjAtom);
  const containerRef = useRef(null);
  const timeInterval = 5;
  const startTime = "7:30 AM";
  const totalMinutes = 9 * 60;
  const periodInterval = 5; //minutes
  const timeWidth = 200;
  const [filteredStaff, setFilteredStaff] = useState<Staff[]>([]);
  const [shiftBreakToEdit, setShiftBreakToEdit] = useState<BreakRecord | null>(null);
  const { sendRequest: updateRTDoc } = useUpdateRTDoc();
  const { sendRequest: addRTDoc } = useAddRTDoc();
  const { updateToDefault } = useTemplates();
  const [resetLoading, setResetLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [clickReady, setClickReady] = useState(true);
  const [newTimeElementData, setNewTimeElementData] = useState<
    BreakType | TimeAssignmentType | null
  >(null);
  const [newTimeElementPath, setNewTimeElementPath] = useState<string | null>(null);
  const [confirmMessage, setConfirmMessage] = useState("");

  const handleDragEnd = async (event: DragEndEvent) => {
    const breakId = event.active.id.toLocaleString();
    const oldStartTime = event.active.data.current?.startTime;
    const oldEndTime = event.active.data.current?.endTime;
    const delta = event.delta.y;
    const changeInMinutes = Math.round(delta / rowHeight) * periodInterval;
    const dtStart = DateTime.fromFormat(oldStartTime, "t").plus({ minutes: changeInMinutes });
    const dtEnd = DateTime.fromFormat(oldEndTime, "t").plus({ minutes: changeInMinutes });
    const endTime = dtEnd.toFormat("t");
    const startTime = dtStart.toFormat("t");
    const currentIndex = breaks.findIndex((shiftBreak) => shiftBreak.id === breakId);
    if (currentIndex !== -1) {
      const mutableArray: BreakRecord[] = [...breaks];
      mutableArray.splice(currentIndex, 1);
      mutableArray.push({
        ...breaks[currentIndex],
        startTime: startTime,
        endTime: endTime,
      });
      setBreaks(mutableArray);
    }

    const currentBreak = breaks.find((shiftBreak) => shiftBreak.id === breakId);
    if (!currentBreak) return;

    await updateRTDoc({
      data: { ...currentBreak, endTime: endTime, startTime: startTime },
      path: `breaks/${currentBreak.staffId}/${currentBreak.id}`,
    });
  };

  const generateBreak = useCallback(
    async (event: MouseEvent) => {
      if (!containerRef.current) return;
      if (event.pageY < 290) return;
      if (!clickReady) return;
      const { offsetLeft } = containerRef.current;
      const xPosition = event.clientX - offsetLeft;
      const yPosition = event.offsetY;
      const xScroll = document.getElementById("scroll-x-5")?.offsetParent?.scrollLeft;
      const dtPeriodStart = DateTime.fromFormat(startTime, "t").plus({
        minutes: Math.floor(yPosition / rowHeight) * 5,
      });
      const newBreak: BreakType = {
        staffId: filteredStaff[Math.floor((xPosition + (xScroll ? xScroll : 0)) / columnWidth)].id,
        startTime: dtPeriodStart.toFormat("t"),
        endTime: "8:00AM",
        label: "Break",
      };
      setNewTimeElementData(newBreak);
      setNewTimeElementPath(`breaks/${newBreak.staffId}`);
      setConfirmMessage(
        `Are you sure you want to add a new Break to ${
          staffObj
            ? staffObj[newBreak.staffId].firstName + " " + staffObj[newBreak.staffId].lastName
            : "User Not Found"
        } at ${dtPeriodStart.toFormat("t")} `
      );
      setConfirmOpen(true);
    },
    [rowHeight, columnWidth, filteredStaff, clickReady, staffObj]
  );

  useEffect(() => {
    const tempRows: number[] = [];
    const neededRows = Math.ceil(totalMinutes / periodInterval);
    setPageHeight(neededRows * rowHeight);
    for (let i = 0; i < neededRows * rowHeight; i = i + rowHeight) {
      tempRows.push(i);
    }
    setRows(tempRows);
  }, [rowHeight, totalMinutes]);

  useEffect(() => {
    if (!filteredStaff) return;
    setPageWidth(filteredStaff.length * columnWidth);
  }, [filteredStaff, columnWidth]);

  useEffect(() => {
    if (!filteredStaff || filteredStaff.length === 0) return;
    window.addEventListener("dblclick", generateBreak);
    return () => {
      window.removeEventListener("dblclick", generateBreak);
    };
  }, [filteredStaff, generateBreak]);

  const handleScroll = (event: any) => {
    const labelElement = document.getElementById("label-scroll");
    const scheduleElement = document.getElementById("container-drag-ref");
    if (!labelElement || !scheduleElement) return;
    labelElement.scroll({ left: scheduleElement.scrollLeft });
  };

  const handleResetAll = async () => {
    setResetLoading(true);
    const promises: Array<Promise<boolean | null>> = [];
    filteredStaff.forEach(async (staffMember) => {
      promises.push(updateToDefault(staffMember.id));
    });
    await Promise.all(promises);
    setResetLoading(false);
  };

  return (
    <>
      <Box>
        <Grid container spacing={2} sx={{ pl: 2, pr: 2 }} alignContent="center">
          <Grid item xs={6}>
            <Typography sx={{ ml: 4, mb: 2, mt: 1 }} variant="h2">
              Schedule By Staff
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            {resetLoading ? (
              <CircularProgress />
            ) : (
              <Button sx={{ mt: 2 }} onClick={handleResetAll} variant="contained">
                Reset All
              </Button>
            )}
          </Grid>
        </Grid>
        <DepartmentFilter
          setClickReady={setClickReady}
          filteredStaff={filteredStaff}
          setFilteredStaff={setFilteredStaff}
        />
        <StaffLabels
          timeWidth={timeWidth}
          columnWidth={columnWidth}
          filteredStaff={filteredStaff}
        />
        <Box sx={{ height: "65VH", overflow: "scroll", display: "flex", flexWrap: "nowrap" }}>
          <div style={{ width: `${timeWidth}px` }}>
            <div style={{ position: "relative" }}>
              <TimeSidebar
                rowHeight={rowHeight}
                startTime={startTime}
                rows={rows}
                periodInterval={periodInterval}
                timeWidth={timeWidth}
              />
            </div>
          </div>
          <div style={{ width: `${pageWidth + timeWidth}px` }}>
            <div
              onScroll={handleScroll}
              style={{
                position: "relative",
                overflowX: "scroll",
                height: `${pageHeight}px`,
              }}
              id="container-drag-ref"
              ref={containerRef}
            >
              <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                <>
                  <ScheduleGrid
                    rowHeight={rowHeight}
                    columnWidth={columnWidth}
                    pageWidth={pageWidth}
                    pageHeight={pageHeight}
                    firstColumnWidth={0}
                  />

                  {filteredStaff &&
                    filteredStaff.map((staffMember, index) => (
                      <div key={staffMember.id}>
                        <StaffShift
                          left={columnWidth * index + timeWidth}
                          rowHeight={rowHeight}
                          staffMember={staffMember}
                          startTime={startTime}
                          timeInterval={timeInterval}
                          columnWidth={columnWidth}
                        />
                        <StaffMemberBreaks
                          rowHeight={rowHeight}
                          columnWidth={columnWidth}
                          staffMember={staffMember}
                          startTime={startTime}
                          timeInterval={timeInterval}
                          staff={filteredStaff}
                          setShiftBreakToEdit={setShiftBreakToEdit}
                          page="by-staff"
                        />
                      </div>
                    ))}
                </>
              </DndContext>
            </div>
          </div>
        </Box>
      </Box>
      {shiftBreakToEdit && (
        <EditLabelDialog
          shiftBreakToEdit={shiftBreakToEdit}
          setShiftBreakToEdit={setShiftBreakToEdit}
          setClickReady={setClickReady}
        />
      )}
      {confirmOpen && (
        <ConfirmAddDialog
          open={confirmOpen}
          setOpen={setConfirmOpen}
          message={confirmMessage}
          data={newTimeElementData}
          path={newTimeElementPath}
        />
      )}
    </>
  );
}

export default ViewScheduleContainer;
