import React, { useEffect, useRef, useState } from "react";
import { BreakRecord, Staff } from "../../types/types";
import { DateTime, Interval } from "luxon";
import { useDraggable } from "@dnd-kit/core";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, IconButton, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { staffObjAtom } from "../../recoil/staffAtoms";
import DeleteIcon from "@mui/icons-material/Delete";
import useUpdateRTDoc from "../../hooks/useUpdateRTDoc";
import useDeleteRTDoc from "../../hooks/useDeleteRTDoc";

type Props = {
  rowHeight: number;
  columnWidth: number;
  startTime: string;
  timeInterval: number;
  staffMember: Staff;
  shiftBreak: BreakRecord;
  staff: Staff[];
  setShiftBreakToEdit?: (pV: BreakRecord | null) => void;
  page: "by-class" | "by-staff" | "my-schedule";
};

const ShiftBreak = ({
  rowHeight,
  columnWidth,
  startTime,
  timeInterval,
  staffMember,
  shiftBreak,
  staff,
  page,
  setShiftBreakToEdit,
}: Props) => {
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const { sendRequest: updateRTDoc } = useUpdateRTDoc();

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: shiftBreak.id,
    data: { startTime: shiftBreak.startTime, endTime: shiftBreak.endTime },
  });
  const staffObj = useRecoilValue(staffObjAtom);
  const { sendRequest: deleteRTDoc } = useDeleteRTDoc();

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    document.addEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    if (!shiftBreak) return;
    const dtEndTime = DateTime.fromFormat(shiftBreak.endTime, "t");
    const dtStartTime = DateTime.fromFormat(shiftBreak.startTime, "t");
    const diff = Interval.fromDateTimes(dtStartTime, dtEndTime);
    let tempHeight = rowHeight;
    if (diff.isValid) {
      tempHeight = Math.round((diff.length("minutes") / timeInterval) * rowHeight);
    }
    setHeight(tempHeight);
  }, [shiftBreak, timeInterval, rowHeight]);

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (!containerRef.current || containerRef.current.offsetHeight === 0) return;
      setHeight(containerRef.current.offsetHeight);
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleMouseUp = async () => {
    if (!containerRef.current) return;
    document.removeEventListener("mouseup", handleMouseUp);
    const rawHeight = containerRef.current.offsetHeight;
    const newHeight = Math.round(rawHeight / rowHeight) * rowHeight;
    const newMinutes = (newHeight / rowHeight) * timeInterval;
    const dtEndTime = DateTime.fromFormat(shiftBreak.startTime, "t").plus({
      minutes: newMinutes,
    });
    if (newHeight !== height) {
      setHeight(newHeight);
      await updateRTDoc({
        data: { ...shiftBreak, endTime: dtEndTime.toFormat("t") },
        path: `breaks/${shiftBreak.staffId}/${shiftBreak.id}`,
      });
    }
  };

  const calcTop = (periodStart: string) => {
    const dtPeriodStart = DateTime.fromFormat(periodStart, "t");
    const dtStartTime = DateTime.fromFormat(startTime, "t");
    const diff = Interval.fromDateTimes(dtStartTime, dtPeriodStart);
    return Math.round((diff.length("minutes") / timeInterval) * rowHeight);
  };

  const calcLeft = (staffId: string) => {
    let index = 0;
    if (page === "by-staff") {
      index = staff.findIndex((staffMember) => staffMember.id === staffId);
    }
    return index;
  };

  const handleDeleteClick = () => {
    deleteRTDoc({ path: `breaks/${shiftBreak.staffId}/${shiftBreak.id}` });
  };

  const handleNameClick = () => {
    if (setShiftBreakToEdit) {
      setShiftBreakToEdit(shiftBreak);
    }
  };

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: calcTop(shiftBreak.startTime),
          left: calcLeft(shiftBreak.staffId) * columnWidth,
          ...style,
        }}
        ref={setNodeRef}
      >
        <div
          ref={containerRef}
          style={{
            height: `${height}px`,
            overflow: "hidden",
            resize: page === "my-schedule" ? "none" : "vertical",
            backgroundColor: "rgba(224, 164, 244, .8)",
            zIndex: 100,
            position: "relative",
            width: 100,
            boxShadow: "0 0 1px #333",
            borderRadius: 5,
            color: "#333",
          }}
          className="resizeable"
          onMouseDown={handleMouseDown}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: page === "my-schedule" ? "center" : "left",
              alignItems: "center",
            }}
          >
            {page !== "my-schedule" && <DragIndicatorIcon {...listeners} {...attributes} />}
            {page !== "my-schedule" && (
              <IconButton sx={{ padding: 0, mr: "2px" }} onClick={handleDeleteClick}>
                <DeleteIcon fontSize="small" sx={{ color: "#333" }} />
              </IconButton>
            )}
            {staffObj && staffObj[shiftBreak.staffId] && (
              <Typography onClick={handleNameClick} sx={{ fontSize: 12, cursor: "pointer" }}>
                {shiftBreak.label}
              </Typography>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default ShiftBreak;
