import { atom, selector } from "recoil";
import { Staff, StaffFromAPI } from "../types/types";
import { GetRecoilValue } from "recoil";
type Props = {
  get: GetRecoilValue;
};

const createObjGetter = ({ get }: Props) => {
  const staff = get(staffAtom);
  if (!staff) return null;
  const tempObj: { [key: string]: Staff } = {};
  staff.forEach((staffMember) => {
    tempObj[staffMember.id] = staffMember;
  });
  return tempObj;
};

const selectedStaffMemberGetter = ({ get }: Props) => {
  const staff = get(staffAtom);
  const selectedStaffMemberId = get(selectedStaffMemberIdAtom);
  if (!staff || !selectedStaffMemberId) return null;
  const filteredStaff = staff.find((staffMember) => staffMember.id === selectedStaffMemberId);
  return filteredStaff;
};

export const staffAtom = atom<Staff[]>({
  key: "staff",
  default: [],
});

export const loggedInStaffAtom = atom<Staff | null>({
  key: "loggedInStaff",
  default: null,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

export const staffFromAPIAtom = atom<StaffFromAPI[]>({
  key: "staffFromAPI",
  default: [],
});

export const staffObjAtom = selector({
  get: createObjGetter,
  key: "staffObj",
});

export const selectedStaffMemberAtom = selector({
  key: "selectedStaffMember",
  get: selectedStaffMemberGetter,
});

export const selectedStaffMemberIdAtom = atom<string | null>({
  key: "selectedStaffMemberId",
  default: null,
});
