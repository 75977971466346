import { atom, selector } from "recoil";
import { BreakRecord, Homeroom, Staff } from "../types/types";
import { GetRecoilValue } from "recoil";
import { staffAtom } from "./staffAtoms";
type Props = {
  get: GetRecoilValue;
};

export const filteredHomeroomsAtom = atom<Homeroom[]>({
  key: "filteredHomerooms",
  default: [],
});
