import { useState, useCallback } from "react";
import { doc, updateDoc, Timestamp, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";

interface SendRequestProps {
  col: string;
  id: string;
}

const useDeleteDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async ({ col, id }: SendRequestProps): Promise<string | null> => {
    setIsLoading(true);
    setError(null);
    try {
      const docRef = doc(db, col, id);
      await deleteDoc(docRef);
      setIsLoading(false);
      return null;
    } catch (err: any) {
      setError(err.message || "Something went wrong!");
      setIsLoading(false);
      return null;
    }
  }, []);
  return { isLoading, error, sendRequest };
};

export default useDeleteDoc;
