import { atom } from "recoil";
import { PeriodRecord } from "../types/types";

export const periodsAtom = atom<PeriodRecord[]>({
  key: "periods",
  default: [],
});

export const periodsResetAtom = atom({
  key: "periodsReset",
  default: false,
});
