import React from "react";
import { PeriodRecord } from "../../types/types";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

type Props = {
  period: PeriodRecord;
  setPeriodToDeleteId: (pV: string) => void;
  setPeriodToEdit: (pV: PeriodRecord | null) => void;
};

function Period({ period, setPeriodToDeleteId, setPeriodToEdit }: Props) {
  const handleDeleteClick = () => {
    setPeriodToDeleteId(period.id);
  };

  const handleEditClick = () => {
    setPeriodToEdit(period);
  };
  return (
    <>
      <Paper
        sx={{
          mt: 2,
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          verticalAlign: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{`${period.label}: ${period.startTime} - ${period.endTime}`}</Typography>
        <Box>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Paper>
    </>
  );
}

export default Period;
