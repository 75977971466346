import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Staff } from "../../types/types";
import { staffAtom } from "../../recoil/staffAtoms";

type Props = {
  selectedStaff: Staff | null;
  setSelectedStaff: (value: Staff | null) => void;
};

function Header({ selectedStaff, setSelectedStaff }: Props) {
  const [selectedStaffId, setSelectedStaffId] = useState<string | null>(null);
  const staff = useRecoilValue(staffAtom);
  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedStaffId(event.target.value);
  };

  useEffect(() => {
    const filteredStaff = staff.find((staffMember) => staffMember.id === selectedStaffId);
    if (filteredStaff) {
      setSelectedStaff(filteredStaff);
    }
  }, [staff, selectedStaffId, setSelectedStaff]);

  useEffect(() => {
    if (!staff || staff.length === 0) return;
    if (!selectedStaffId) {
      setSelectedStaffId(staff[0].id);
    }
  }, [staff, selectedStaffId]);
  return (
    <>
      {selectedStaffId && (
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} sm={9}>
            <Typography variant="h2">My Schedule</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="selected-homeroom">Select Staff</InputLabel>
              <Select
                value={selectedStaffId}
                labelId="selected-staff"
                required
                label="Select Staff"
                onChange={handleSelectChange}
              >
                {staff &&
                  staff.map((staffMember) => (
                    <MenuItem key={staffMember.id} value={staffMember.id}>
                      {staffMember.firstName} {staffMember.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Header;
