import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { BreakRecord } from "../../types/types";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { breaksResetAtom } from "../../recoil/breakAtoms";
import useUpdateRTDoc from "../../hooks/useUpdateRTDoc";

type Props = {
  shiftBreakToEdit: BreakRecord;
  setShiftBreakToEdit: (pV: BreakRecord | null) => void;
  setClickReady: (pV: boolean) => void;
};

export default function EditLabelDialog({
  shiftBreakToEdit,
  setShiftBreakToEdit,
  setClickReady,
}: Props) {
  const [label, setLabel] = useState(shiftBreakToEdit.label);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: updateRTDoc } = useUpdateRTDoc();
  const setBreaksReset = useSetRecoilState(breaksResetAtom);
  setClickReady(false);
  const handleClose = () => {
    setShiftBreakToEdit(null);
    setClickReady(true);
  };

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const handleSave = async () => {
    await updateRTDoc({
      data: { ...shiftBreakToEdit, label: label },
      path: `breaks/${shiftBreakToEdit.staffId}/${shiftBreakToEdit.id}`,
    });
    setShiftBreakToEdit(null);
    setBreaksReset((pV: boolean) => !pV);
    setClickReady(true);
  };

  return (
    <>
      <Dialog open={Boolean(shiftBreakToEdit)} onClose={handleClose}>
        <DialogTitle>Edit Label</DialogTitle>
        <DialogContent>
          <TextField fullWidth value={label} onChange={handleLabelChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
