import { useEffect, useContext } from "react";
import useGetDocs from "./useGetDocs";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import {
  staffAtom,
  loggedInStaffAtom,
  staffResetAtom,
  selectedStaffMemberIdAtom,
} from "../recoil/staffAtoms";
import { BreakRecord, PeriodRecord, Staff, TimeAssignmentRecord } from "../types/types";
import { AuthContext } from "../providers/AuthProvider";
import {
  parseBreaksResponse,
  parsePeriodsResponse,
  parseStaffResponse,
  parseTimeAssignmentsResponse,
} from "../libraries/parsers";
import { periodsAtom, periodsResetAtom } from "../recoil/periodAtoms";
import {
  timeAssignmentTemplatesAtom,
  timeAssignmentTemplatesResetAtom,
  timeAssignmentsAtoms,
  timeAssignmentsResetAtom,
} from "../recoil/timeAssignmentAtoms";
import {
  breakTemplatesAtom,
  breakTemplatesResetAtom,
  breaksAtom,
  breaksResetAtom,
} from "../recoil/breakAtoms";
import { filteredHomeroomsAtom } from "../recoil/filterAtoms";
import { homeroomsAtom } from "../recoil/atoms";
import { getDatabase, ref, onValue } from "firebase/database";
import useAddRTDoc from "./useAddRTDoc";

const useBootstrapEffect = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const staff = useRecoilValue(staffAtom);
  const homerooms = useRecoilValue(homeroomsAtom);
  const { sendRequest: addRTDoc } = useAddRTDoc();

  //********** SETTERS **********//
  const setStaff = useSetRecoilState<Staff[]>(staffAtom);
  const setPeriods = useSetRecoilState<PeriodRecord[]>(periodsAtom);
  const setBreaks = useSetRecoilState<BreakRecord[]>(breaksAtom);
  const setTimeAssignments = useSetRecoilState<TimeAssignmentRecord[]>(timeAssignmentsAtoms);
  const setBreakTemplates = useSetRecoilState<BreakRecord[]>(breakTemplatesAtom);
  const setTimeAssignmentTemplates = useSetRecoilState<TimeAssignmentRecord[]>(
    timeAssignmentTemplatesAtom
  );
  const [selectedStaffMemberId, setSelectedStaffMemberId] =
    useRecoilState(selectedStaffMemberIdAtom);
  const [filteredHomerooms, setFilteredHomerooms] = useRecoilState(filteredHomeroomsAtom);

  //********** RESETS **********//
  const periodsReset = useRecoilValue(periodsResetAtom);
  const timeAsssignmentsReset = useRecoilValue(timeAssignmentsResetAtom);
  const breaksReset = useRecoilValue(breaksResetAtom);
  const staffReset = useRecoilValue(staffResetAtom);
  const breakTemplatesReset = useRecoilValue(breakTemplatesResetAtom);
  const timeAssignmentTemplatesReset = useRecoilValue(timeAssignmentTemplatesResetAtom);

  useEffect(() => {
    if (staff.length === 0 || selectedStaffMemberId) return;
    setSelectedStaffMemberId(staff[0].id);
  }, [staff, selectedStaffMemberId, setSelectedStaffMemberId]);

  useEffect(() => {
    if (!homerooms || homerooms.length === 0 || filteredHomerooms.length > 0) return;
    setFilteredHomerooms(homerooms);
    console.log("reset triggered");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homerooms, setFilteredHomerooms]);

  useEffect(() => {
    const getStaff = async () => {
      const response = await getDocs<Staff>({ col: "staff" });
      response.sort((a: Staff, b: Staff) => a.firstName.localeCompare(b.firstName));
      if (response) {
        setStaff(parseStaffResponse(response));
      }
    };
    getStaff();
  }, [setStaff, getDocs, staffReset]);

  useEffect(() => {
    const getPeriods = async () => {
      const response = await getDocs<PeriodRecord>({ col: "periods" });
      if (!response) return;
      response.sort((a, b) => (a.sort > b.sort ? 1 : -1));
      setPeriods(parsePeriodsResponse(response));
    };
    getPeriods();
  }, [setPeriods, getDocs, periodsReset]);

  // useEffect(() => {
  //   const getTimeAssignments = async () => {
  //     const response = await getDocs<TimeAssignmentRecord>({ col: "timeAssignments" });
  //     if (!response) return;
  //     const tempTimeAssignments = parseTimeAssignmentsResponse(response);
  //     console.log("triggered")
  //     tempTimeAssignments.forEach((ta) => {
  //       const tempNoId: any = { ...ta };
  //       delete tempNoId.id;
  //       if(tempNoId.staffId === "1BYPft7GYOwOzKCT7zFM"){
  //         console.log("Fou nd")
  //       }
  //       //addRTDoc({ data: tempNoId, path: `timeAssignments/${tempNoId.staffId}` });
  //     });
  //     setTimeAssignments(parseTimeAssignmentsResponse(response));
  //   };
  //   getTimeAssignments();
  // }, []);

  useEffect(() => {
    const db = getDatabase();
    const timeAssignmentsRef = ref(db, `timeAssignments/`);
    onValue(timeAssignmentsRef, (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        setTimeAssignments([]);
        return;
      }
      const staffIdsArray = Object.keys(data);
      const tempArray: TimeAssignmentRecord[] = [];
      staffIdsArray.forEach((key) => {
        const recordKeysArray = Object.keys(data[key]);
        recordKeysArray.forEach((recordKey) => {
          tempArray.push({
            ...data[key][recordKey],
            id: recordKey,
          });
        });
      });
      setTimeAssignments(tempArray);
    });
  }, [setTimeAssignments]);

  useEffect(() => {
    const db = getDatabase();
    const breaksRef = ref(db, `breaks/`);
    onValue(breaksRef, (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        setBreaks([]);
        return;
      }
      const staffIdsArray = Object.keys(data);
      const tempArray: BreakRecord[] = [];
      staffIdsArray.forEach((key) => {
        const recordKeysArray = Object.keys(data[key]);
        recordKeysArray.forEach((recordKey) => {
          tempArray.push({
            ...data[key][recordKey],
            id: recordKey,
          });
        });
      });
      setBreaks(tempArray);
    });
  }, [setBreaks]);

  useEffect(() => {
    const getTimeAssignmentTemplates = async () => {
      console.log("get new time assignment templates from bootstraps");
      const response = await getDocs<TimeAssignmentRecord>({ col: "timeAssignmentTemplates" });
      if (!response) return;
      setTimeAssignmentTemplates(parseTimeAssignmentsResponse(response));
    };
    getTimeAssignmentTemplates();
  }, [setTimeAssignmentTemplates, getDocs, timeAssignmentTemplatesReset]);

  // useEffect(() => {
  //   const getBreaks = async () => {
  //     const response = await getDocs<BreakRecord>({ col: "breaks" });
  //     if (!response) return;
  //     const tempBreaks = parseBreaksResponse(response);
  //     tempBreaks.forEach((ta) => {
  //       const tempNoId: any = { ...ta };
  //       delete tempNoId.id;
  //       //addRTDoc({ data: tempNoId, path: `breaks/${tempNoId.staffId}` });
  //     });
  //     setBreaks(parseBreaksResponse(response));
  //   };
  //   getBreaks();
  // }, [setBreaks, getDocs, breaksReset, addRTDoc]);

  useEffect(() => {
    const getBreakTemplates = async () => {
      const response = await getDocs<BreakRecord>({ col: "breakTemplates" });
      if (!response) return;
      setBreakTemplates(parseBreaksResponse(response));
    };
    getBreakTemplates();
  }, [setBreakTemplates, getDocs, breakTemplatesReset]);
};
export default useBootstrapEffect;
